@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-stream-sources {
   .Dialog();
   width : 614px;
   text-align : right;
   height : 400px;
   padding-top : 40px;
   .ch-form-field-select {
      label {
         color : @clrFG1;
         display : block;
         font-size : 22px;
         font-weight : 700;
         text-align : left;
         margin-bottom : 10px;
      }
      select {
         position : relative;
         width: 100%;
         max-width: 100%;
         margin : 10px 0px 30px;
         font-size: 20px;
         color : @clrFG1;
         background-color : @clrBG0;
         background-image : url('@{pathProd}img/icon_arrow_down_fg1.svg');
         background-position: right center;
         background-repeat: no-repeat;
         background-size: contain;
         border-radius: 4px;
         appearance : none;
         line-height: 40px;
         padding : 0px 44px 0px 15px;
         border: @hairline;
         &:focus {
            outline : none;
            line-height: 40px;
         }
      }
      svg {
         &.ch-select-icon {
            display : none;
         }
      }
   }
}
