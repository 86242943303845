@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.menu-send {
  .HardwareAccelerate();
  display : inline-block;
  position : absolute;
  top : -@heightSendMenu;
  left : 0px;
  background : @clrBG1;
  color : @clrFG1;
  width : 6 * @widthSendMenuBtn;
  height : @heightSendMenu;
  padding : 0px;
  border-radius : @borderRadius;// @borderRadius @borderRadius 0px;
  //border : 1px solid @clrFG1;
    .menu-send-item {
      .NonSelectable();
      position : relative;
      display : inline-block;
      width : @widthSendMenuBtn;
      height : @heightSendMenu;
      margin : 0px;
      border-radius: @borderRadius;
      overflow : visible;
      background : transparent;
      &:hover {
        background : @clrBG3;
        outline : none;
      }
      &:focus {
        outline : none;
      }
      &:before {
        display : inline-block;
        position : absolute;
        width : @widthSendMenuBtn;
        height : @heightSendMenu;
        content : '';
        left : 0px;
        top : 0px;
        filter : @filterFG1;
      }
      .svg-in-object {
        display : inline-block;
        position : absolute;
        width : @heightSendMenu * 1.77;
        height : @heightSendMenu;
        left : 0px;
        top : 0px;
        filter : @filterFG1;
        z-index : 1;
        pointer-events: none;
      }
      &.fx {
        overflow : hidden;
        text-align : center;
        width : @heightSendMenu * 1.77;
        border: 1px solid rgba( 255, 255, 255, 0.1 );
        &:hover {
          background : rgba( 0, 0, 0, 0 );
          border : 1px solid @clrFG1;
        }
        &:before {
          display : inline-block;
          position : absolute;
          width : @widthSendMenuBtn;
          height : @heightSendMenu;
          left : 0px;
          top : 0px;
          background : rgba( 0, 0, 0, 0 );
          z-index : 2;
        }
        .svg-in-object {
          &.red {
            filter : @filterRed;
          }
          &.yellow {
            filter : @filterYellow;
          }
          &.green {
            filter : @filterGreen;
          }
          &.purple {
            filter : @filterPurple;
          }
        }
      }
      &.vibes {
        &:before {
          .BGimage( '@{pathProd}img/icon_vibe_send.svg' );
          background-size : 80%;
          top : -2px;
        }
      }
      &.heart {
        &:before {
          .BGimage( '@{pathProd}img/icon_heart.svg' );
        }
        &.red {
          &:before {
            filter : @filterRed;
          }
        }
        &.yellow {
          &:before {
            filter : @filterYellow;
          }
        }
        &.green {
          &:before {
            filter : @filterGreen;
          }
        }
        &.purple {
          &:before {
            filter : @filterPurple;
          }
        }
        &[data-heart-id="red"] {
          &:before {
            filter : @filterRed;
          }
        }
        &[data-heart-id="yellow"] {
          &:before {
            filter : @filterYellow;
          }
        }
        &[data-heart-id="green"] {
          &:before {
            filter : @filterGreen;
          }
        }
        &[data-heart-id="purple"] {
          &:before {
            filter : @filterPurple;
          }
        }
      }
      &.fire {
        &:before {
          .BGimage( '@{pathProd}img/icon_fire.svg' );
          background-size : 80%;
        }
      }
      &.gift {
        &:before {
          .BGimage( '@{pathProd}img/icon_gift.svg' );
        }
      }
      &.mojo {
        &:before {
          .BGimage( '@{pathProd}img/icon_mojo.svg' );
        }
      }
      &.emote {
        &:before {
          .BGimage( '@{pathProd}img/icon_smiling_slightly.svg' );
        }
      }
      .menu-hearts {
        position : absolute;
        width : @widthSendMenuBtn;
        height : @widthSendMenuBtn * 4;
        bottom : @heightSendMenu;
        left : 0px;
        background : @clrBG0;
        border-radius: @borderRadius 0px 0px 0px;
      }
      .menu-fx {
        filter : none;
        position : absolute;
        width : ( @heightSendMenu * 1.77 ) * 3;
        height : @heightSendMenu;
        top : 0px;
        left : @widthSendMenuBtn;
        background : @clrBG0;
        border-radius: 0px @borderRadius @borderRadius 0px;
      }
    }
}
