@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-table-waitlist {
   .Dialog( 900px );
   font-size : unset;
   .body {
      text-align: left;
   }
   .footer {
      text-align : center;
   }
}
