@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-create-star {
  .Dialog( @widthDialog );
  padding: 50px 40px 70px 40px;
  .body {
    padding-top: 25px;
  }
}


