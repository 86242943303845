@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@heightFooter : 50px;

.chat-panel-mini {
    position : absolute;
    width : 100%;
    overflow : hidden;
    height : 0px;
    .TransAllCubic( @transDurationFast );
    white-space: nowrap;
    background: @clrBG0;
    // backdrop-filter: blur(8px);
    border : 1px solid transparent;
    opacity : 0;
    &.displayed {
        height : 250%;
        border : @hairline;
        opacity : 1;
    }
    .chat-panel {
        height : calc( 100% - @heightFooter );
        /* it is a puzzle why this padding is working differently on different Mac / Chrome combos */
        padding: 2px 16px 2px 0px;
        overflow-x : hidden;
        overflow-y : scroll;
        // hide the scrollbar by being 16px too wide
        width : calc( 100% + @widthScrollbar );
        .chat-msg {
            width : 100%;
            text-align : left;
            margin : 3px 0px;
            .from-badge {
                display : none;
            }
            .msg {
                margin : 0px;
                display : inline-block;
                font-size : 16px;
                text-overflow: ellipsis;
                line-height : 21px;
                width: calc(100% - 5px);
                background : transparent;
                border-radius : 0 6px 6px 0;
                .invisi-txt {
                    display : none;
                }
            }
            &.me {
                .msg {
                    background : rgba( 0, 107, 0, 0.5 );
                    border-radius : 6px 0 0 6px;
                    transform: translateX( 20px );
                }
            }
        }
    }
    .footer {
        position : absolute;
        bottom : 0px;
        width : 100%;
        height : @heightFooter;
        padding : 8px;
        input {
            width : 100%;
            position : absolute;
            top : 0px;
            bottom : 0px;
            left : 0px;
            margin : auto;
            padding : 10px;
        }
    }
}
