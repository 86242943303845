@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-stream {
  &.star {
    .poster {
      .RainbowBorder( 1px );
      position : absolute;
      top : 0px;
      left : 0px;
      width : 100%;
      height : 100%;
      background : @clrBG0;
      z-index : @zIndexChromeUnder;
      overflow : hidden;
      &:before {
        position : absolute;
        width : 100%;
        height : 100%;
        content : '';
        left : 0px;
        top : 0px;
        .BGimage( '@{pathProd}img/vibe_poster_big.svg' );
      }
    }
  }
}
