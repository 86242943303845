@import "../../../css/vars.less";
@import "../../../css/mixins.less";


.drop-target {
   position : absolute;
   width : @cellDim;
   height : @cellDim;
   background : transparent;
   border-right : @hairline;
   border-bottom : @hairline;
   z-index : 0;
   &.targeted {
      border : 3px solid @clrWhite;
   }
   .Coords();
   &[data-coord="stage-center"]{
      border-radius : 50%;
      top : 0px;
      left : 0px;
      right : 0px;
      bottom: 0px;
      margin : auto;
      width : @cellDim * 1.5;
      height : @cellDim * 1.5;
      z-index : 1;
      border : none;
      &.surfaced {
         z-index : 3;
      }
      &.targeted {
         .RainbowBorder( 4px );
      }
   }
   &[data-coord="stage-tl"]{
      &.surfaced {
         z-index : 2;
      }
   }
   &[data-coord="stage-tr"]{
      &.surfaced {
         z-index : 2;
      }
   }
   &[data-coord="stage-bl"]{
      &.surfaced {
         z-index : 2;
      }
   }
   &[data-coord="stage-br"]{
      &.surfaced {
         z-index : 2;
      }
   }
}
