@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.stream-panel-overlay {
    width : calc( 100% + 4px );
    height : calc( 100% + 4px );
    z-index : 3;
    position : absolute;
    top : -2px;
    left : -2px;
    padding : 0;
    margin : 0;
    outline : none;
    .PulseBorder();
    &.chat-panel-displayed {
        .ClrBorderSide();
    }
    &:focus {
        outline : none;
    }
    &.msg {
        .chat-msg {
            opacity : 1;
        }
    }
    .chat-msg {
        position : absolute;
        margin : auto;
        top : 0px;
        left : 0px;
        bottom : 0px;
        right : 0px;
        text-align : center;
        font-size : 12pt;
        max-width : unset;
        .TransAllCubic( @transDuration );
        opacity : 0;
        // background : transparent;
        .from-badge {
            display : none;
        }
        .msg {
            margin : auto;
            position : absolute;
            background : @clrBG0;
            width : 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border : 1px solid white;
            .invisi-txt {
                display : none;
            }
        }
    }
}
