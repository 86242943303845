@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.MuiFormControl-root.MuiTextField-root.input-text-vibe {
  width : 100%;
  margin-bottom : 17px;
  display : inline-block;
  text-align : left;
  &.vibe-error {
    margin-bottom: 0px;
  }
  label.MuiInputLabel-outlined {
    color : @clrPlaceholder;
    transform: translate(14px, 14px) scale(1);
    &[data-shrink="true"] {
      transform: translate(11px, -7px) scale(0.8);
    }
    &.Mui-focused {
      color : @clrYellow !important;
      font-size : 21px;
    }
  }
  .MuiOutlinedInput-root {
    height: @heightInputText;
    display : inline-block;
    width: 100%;
    text-align : left;
    input.MuiInputBase-input.MuiOutlinedInput-input {
      font: inherit;
      color: @clrFG1 !important;
      width: calc( 100% - 10px );
      border: 0;
      height: @heightInputText;
      text-align : left;
      margin: 0px;
      display : inline-block;
      padding : 0px 0px 0px 10px;
      background: @clrBG1 !important;
      animation-name: mui-auto-fill-cancel !important;
      letter-spacing: inherit;
      animation-duration: 10ms !important;
      -webkit-tap-highlight-color: transparent;
      appearance: none !important;
      &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
        transition-delay: 5000s;
        &:hover, &:focus, &:active {
          transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
          transition-delay: 5000s;
        }
      }
    }
    fieldset {
      border : 1px solid @clrDisabled;
      border-radius: 6px;
      legend {
        // just showing structure
        span {
          // just showing structure
        }
      }
    }
    &:hover {
      fieldset {
        border : 1px solid @clrFG1;
      }
    }
    &.Mui-focused {
      fieldset {
        border : 1px solid @clrYellow;
      }
    }
    &.Mui-error {
      fieldset {
        border : 2px solid @clrError;
      }
      &:hover {
        fieldset {
          border : 1px solid @clrFG1;
        }
      }
    }
  }
  .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error{
    margin-top : 0px;
    color : @clrError;
  }
}

