@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@btnChatDim : 45px;
@heightFooter : 50px;

.panel-chat {
    position :  absolute;
    width : @widthSidePanel;
    height : calc( 100vh - @heightTopBar );
    top : @heightTopBar;
    right : -@widthSidePanel;
    color : @clrFG1;
    background : @clrBG0;
    .TransAllCubic( @transDurationVeryFast );
    .btn-chat {
        -webkit-appearance: none;
        appearance: none;
        position : absolute;
        display : block;
        right : calc( 100% + 5px );
        top : 31px;
        border-radius : 9px;
        background-color : @clrBG0;
        border : none;
        width : @btnChatDim;
        height : @btnChatDim;
        outline: none;
        overflow : hidden;
        &:after {
            content : '';
            top : 0px;
            left : 0px;
            position : absolute;
            width : @btnChatDim;
            height : @btnChatDim;
            .BGimage( '@{pathProd}img/icon_chat.svg' );
            filter : @filterFG1;
            background-size: 80%;
        }
        &:hover {
            background-color : @clrBG2;
        }
        &:focus, &:active {
            outline : none;
        }
    }
    .chat-panel {
        position : relative;
        height : calc( 100% - @heightFooter );
        padding : 5px 0px 0px 5px;
        overflow-y : scroll;
        // hide the scrollbar by being 16px too wide
        width : calc( 100% + @widthScrollbar );
        // border : 1px solid red;
    }
    .footer {
        position : absolute;
        bottom : 0px;
        width : 100%;
        height : @heightFooter;
        padding : 8px;
        input {
            width : 100%;
            position : absolute;
            top : 0px;
            bottom : 0px;
            left : 0px;
            margin : auto;
        }
    }
    &.displayed {
        right : 0px;
        .btn-chat {
            &:after {
                &:after {
                    .BGimage( '@{pathProd}img/icon_chat_close.svg' );
                    background-size: 80%;
                }
            }
        }
    }
}
