@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.input-tags {
  input{
    .Input(820px);
    margin-bottom : 5px;
  }
  .MuiFormControl-root {
    margin-bottom : 0px !important;
  }
  .blurb {
    display : none;
    text-align : left;
    color : @clrDisabled;
    &.displayed {
      display : block;
    }
  }
  .tag-labels{
    width : 820px;
    text-align: left;
    padding: 3px 0;
    margin : 0 auto;
    .tag-label{
      .NonSelectable();
      height: 25px;
      line-height: 25px;
      background: @clrBG2;
      position : relative;
      font-size : 16px;
      padding : 0px 25px;
      border-radius: 6px;
      display: inline-block;
      margin: 0 5px 0 0;
      overflow : hidden;
      outline: none;
      &:hover, &:focus, &:active {
        outline : none;
        .tag-delete {
          &:before {
            filter : @filterRed;
          }
        }
      }
      &:after{
        content : ' ';
      }
      .tag-delete {
        position : absolute;
        display : inline-block;
        top : 0px;
        left : 0px;
        &:before{
          content : '';
          .BGimage( '@{pathProd}img/icon_menu_close.svg' );
          filter : @filterDisabled;
          position : absolute;
          left : 0px;
          top : 0px;
          width : 15px;
          height : 15px;
        }
      }
    }
  }
}
