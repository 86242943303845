@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.activity-menu {
    position : absolute;
    left : 0px;
    top : @heightTopBar;
    z-index : @zIndexChromeUnder;
    background : @clrBG1;
    color : @clrFG1;
    width : @widthActivityMenuDismissed;
    height : calc( 100vh - @heightTopBar );
    padding : 5px;
    text-align : left;
    border-right : 1px solid @clrBG1;
    overflow : hidden;
    .Transition( width, @transDurationVeryFast, cubic-bezier(0.7, 0.01, 0.3, 1) );
    @media @queryMedium {
        top : @heightTopBarSmall;
    }
    @media @querySmall {
        display : none;
    }
    &:hover, &.displayed, &.pinned {
        width : @widthActivityMenuDisplayed;
        border-right : 1px solid white;
        overflow : visible;
        .title {
            width : @widthActivityMenuItem;
            color : @clrFG1;
            .TransAllCubic( @transDuration );
        }
        button {
            &.btn-toggle-pinned {
                background : transparent;
                &:before {
                    left : unset;
                    right : 0px;
                }
            }
        }
    }
    &.pinned {
        button {
            &.btn-toggle-pinned {
                background : transparent;
                &:before {
                    .FlipHorizontalNot();
                }
            }
        }
    }
    .title {
        width : 0px;
        overflow : hidden;
        position : absolute;
        left : 5px;
        top : 5px;
        background : transparent;
        height : 45px;
        line-height : 45px;
        font-size : 22px;
        font-weight : 800;
        color : rgba( 255, 255, 255, 0 );
        padding-left : 5px;
        .TransAllCubic( @transUltraFast );
    }
    .separator {
        border-bottom : 1px solid @clrWhiteAlpha2;
        width : @widthActivityMenuItem - 20px;
        height : 3px;
        background : transparent;
        margin-bottom : 6px;
        margin-left : 16px;
    }
    button {
        .NonSelectable();
        -webkit-appearance: none;
        appearance: none;
        display : inline-block;
        position : relative;
        color : @clrFG1;
        margin-left : 6px;
        text-align : left;
        padding : 12px 12px 12px 50px;
        background : @clrWhiteAlpha0;
        border : none;
        outline: none;
        border-radius : 4px;
        font-size : 18px;
        font-weight : 700;
        width : @widthActivityMenuItem;
        min-width : @widthActivityMenuItem;
        cursor : pointer;
        height : 45px;
        overflow : hidden;
        text-overflow: ellipsis;
        .TransAllCubic( @transDurationVeryFast );
        &:before {
            .TransAllCubic( @transDurationVeryFast );
            display : inline-block;
            position : absolute;
            width : 43px;
            height : 43px;
            content : '';
            left : 0px;
            top : 1px;
            filter : @filterFG1;
            pointer-events: none;
        }
        &:hover {
            outline : none;
            background : @clrWhiteAlpha2;
            width : unset;
        }
        &.btn-mute-everyone {
            &:before {
                .BGimage( '@{pathProd}img/icon_speaker.svg' );
            }
            &.off {
                &:before {
                    .BGimage( '@{pathProd}img/icon_speaker_off.svg' );
                }
            }
        }
        &.btn-love {
            &:before {
                filter: @filterRed;
                .BGimage( '@{pathProd}img/icon_heart.svg' );
            }
            &.clicked {
                &:before {
                    transform : scale( 150% );
                }
            }
        }
        &.btn-home {
            &:before {
                .BGimage( '@{pathProd}img/icon_home.svg' );
            }
        }
        &.btn-airhorn {
            &:before {
                .BGimage( '@{pathProd}img/icon_airhorn.svg' );
            }
            &.clicked {
                &:before {
                    filter : @filterGreen;
                    transform : rotate( -15deg );
                }
            }

        }
        &.btn-table {
            white-space: nowrap;
            &:before {
                .BGimage( '@{pathProd}img/icon_polycast.svg' );
                background-size : 80%;
                transform : rotate( 0deg );
                filter : @filterFG1;
            }
            &:hover {
                background : @clrBG1;
                &:before {
                    transform : rotate( 90deg );
                }
            }
            &.approved {
                color : #43883C;
                &:before {
                    filter: @filterGreen;
                }
            }
            &.pending {
                color : #FFCD6A;
                &:before {
                    filter: @filterYellow;
                }
                &:after {
                    content : ' - pending';
                }
            }
        }
        &.btn-toggle-pinned {
            &:hover {
                background : transparent;
                outline : none;
            }
            &:before {
                .FlipHorizontal();
                .BGimage( '@{pathProd}img/icon_arrow_close.svg' );
            }
        }
    }
    .slider {
        position : absolute;
        bottom : 30px;
        left : 20px;
    }
}
