@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@badgeDim: 35px;
@gutter: 35px;

.chat-msg {
  position: relative;
  width: 100%;
  text-align: left;
  // white-space: nowrap;
  .from-badge {
    position: absolute;
    .NonSelectable();
    display: inline-block;
    top: 4px;
    border-radius: 12px 6px 6px 6px;
    height: @badgeDim;
    background: @clrBG3;
    .ClrBG();
    text-align : center;
    line-height : @badgeDim;
    font-weight : 800;
    .TextShadow( 2px );
    z-index : 1;
    padding: 0 10px;
  }
  .msg {
    z-index : 0;
    display: inline-block;
    text-align: left;
    width : 100%;
    background : transparent;
    white-space: normal;
    line-height: 27px;
    padding: 8px;
    max-height : 200px;
    text-overflow: ellipsis;
    .invisi-txt {
      color : rgba( 255, 255, 255, 0 );
      padding-right: 12px;
    }
  }
  &.vibetron {
    // background : @clrBG2;
    font-weight : 700;
    .from-badge {
      width : @badgeDim;
      height : @badgeDim;
      top: 4px;
      .BGimage( '@{pathProd}img/icon_vibe_with_me.png' );
    }
  }
  &.tall {
    .msg {
      padding-bottom : 2px;
    }
  }

  &.me {
    .from-badge {
      background : @clrBGgreen;
    }
  }

}
