@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-invite {
  text-align : left;
  width : 100%;
  .invitee-tag {
    .NonSelectable();
    height: 25px;
    line-height: 25px;
    background: @clrBG2;
    position: relative;
    font-size: 16px;
    padding: 0px 25px;
    border-radius: 6px;
    display: inline-block;
    margin: 0 5px 0 0;
    overflow: hidden;
    outline: none;
    &:hover, &:focus, &:active {
      outline: none;
      .tag-delete {
        &:before {
          filter: @filterRed;
        }
      }
    }
    &:after {
      content: ' ';
    }
    .tag-delete {
      position: absolute;
      display: inline-block;
      top: 0px;
      left: 0px;
      &:before {
        content: '';
        .BGimage('@{pathProd}img/icon_menu_close.svg');
        filter: @filterDisabled;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 15px;
        height: 15px;
      }
    }
  }
  textarea {
    .Input();
    width : 100%;
    resize : none;
    /*
    vertical-align: top;
    height: 46px !important;
    line-height: 46px;
    padding: 0;
    overflow: hidden;
    resize: none;
    font-size: 16px;
    font-weight: 600;
    border: 0 !important;
    margin: 5px 10px 5px 0px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    font-size: 16px;
    display: inline-block;
    */
  }
}
