@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.menu-user {
    position : absolute;
    right : 4px;
    top : 4px;
    @media @querySmall {
        top : -5px;
    }
    @media @queryMedium {
        top : -5px;
    }
    .btn-user {
        position : absolute;
        right : 0px;
        -webkit-appearance: none;
        appearance: none;
        overflow : hidden;
        border-radius : 10%;
        width : calc( @heightTopBar +  20px );
        height : calc( @heightTopBar - 8px );
        background : transparent;
        border : none;
        outline : none;
        .TransAllCubic( @transDurationVeryFast );
        &:focus, &:active {
            outline : none;
        }
        &:hover {
            background : @clrBG3;
        }
        img {
            position: absolute;
            top: 0px;
            left: -6px;
            bottom: 0px;
            margin: auto;
            width : @heightTopBar;
            height : @heightTopBar - 10px;
        }
        &:before {
            content : '';
            position : absolute;
            right : -5px;
            top : 0px;
            bottom : 0px;
            margin : auto;
            filter : @filterFG1;
            height : @heightTopBar - 10px;
            width : @heightTopBar - 10px;
            .BGimage( '@{pathProd}img/icon_burger.svg' );
            background-size : 85%;
        }
    }
    .user-menu {
        border : 1px solid white;
        border-top : none;
        border-right : none;
        border-radius: 0px 0px 0px 10px;
        position : absolute;
        top : calc( @heightTopBar - 4px );
        right : -4px;
        width : @widthMenu;
        .TransAllCubic( @transDurationFast );
        z-index : @zIndexChrome;
        background : @clrBG1;
        font-size : 16px;
        font-weight : 400;
        padding : 10px;
        display : none;
        &.displayed {
            display : inline-block;
        }
        .identity {
            position : relative;
            height : 43px;
            .avatar {
                position : absolute;
                left : 0px;
                top : 0px;
                height : 50px;
                width : 50px;
            }
            .user-name {
                position : absolute;
                right : 0px;
                top : 0px;
                height : 50px;
                line-height : 50px;
                font-size: 20px;
                padding-right: 13px;
            }
        }
        .hr {
            display : block;
            height : 1px;
            width : 95%;
            background : @clrBG2;
            margin : 10px auto;
        }
        .username {
            color : @clrBrand;
            font-size : 22px;
            font-weight : 900;
        }
        .item-menu {
            .NonSelectable();
            display : block;
            position : relative;
            color : @clrFG1;
            margin : 0px auto;
            text-align : left;
            padding : 12px 12px 12px 50px;
            border-radius : 4px;
            font-weight : 700;
            font-size : 18px;
            outline : none;
            &:hover {
                background : @clrBG3;
                outline : none;
            }
            &:focus {
                outline : none;
            }
            &:before {
                display : inline-block;
                position : absolute;
                width : 43px;
                height : 43px;
                content : '';
                left : 0px;
                top : 0px;
                filter : @filterFG1;
            }
            &.icon-info {
                &:before {
                    .BGimage( '@{pathProd}img/icon_circle_empty_info.svg' );
                }
            }
            &.icon-dancer {
                &:before {
                    .BGimage( '@{pathProd}img/icon_dancer.svg' );
                }
            }
            &.icon-account {
                &:before {
                    .BGimage( '@{pathProd}img/icon_settings.svg' );
                }
            }
            &.icon-mojo {
                &:before {
                    .BGimage( '@{pathProd}img/icon_mojo.svg' );
                }
            }
            &.icon-subs {
                &:before {
                    .BGimage( '@{pathProd}img/icon_star.svg' );
                }
            }
            &.icon-signout {
                &:before {
                    .BGimage( '@{pathProd}img/icon_logout.svg' );
                }
            }
            &.icon-bar-graph {
                &:before {
                    .BGimage( '@{pathProd}img/icon_adjust.svg' );
                }
            }
            &.icon-star {
                &:before {
                    .BGimage( '@{pathProd}img/icon_star.svg' );
                }
            }
            &.icon-equalizer {
                &:before {
                    .BGimage( '@{pathProd}img/icon_equalizer_dots.svg' );
                }
            }
        }
    }
}
