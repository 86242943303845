@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@w: 820px;
.carousel {
    .stage {
        .tile {
            .panel-profile-club {
                border-radius: 10px;
                overflow: hidden;
                .profile-panel {
                    h3 {
                        &.left {
                            display: none;
                        }
                    }
                    .menu {
                        display: none;
                    }
                    .panel-img-cover {
                        height: 150px;
                        border-radius: 0px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        .btn-edit-profile, .btn-edit-cover {
                            display: none;
                        }
                    }
                    .profile-img {
                        width: 170px;
                        height: 170px;
                        margin-top: -160px;
                        .btn-edit-profile-img {
                            display: none;
                        }
                    }
                    .input, textarea {
                        display: none;
                    }
                }
            }
        }
    }
}
.panel-profile-club {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    margin: 0px auto;
    .initial-panel {
        .SubPanel();
        text-align: left;
        padding-bottom: 70px;
        button {
            .BtnDefault();
            position: absolute;
            right: 15px;
            bottom: 15px;
        }
    }
    .profile-panel {
        text-align: center;

        h2, h3, h4 {
            text-align: center;
            width: @w;
            margin: 20px auto;
            &.left {
                text-align: left;
            }
        }
        h2 {
            font-size: 36px;
        }
        p {
            width: @w;
            margin: 0 auto;
            &.left {
                text-align: left;
            }
        }
        .menu {
            display: none;
            position: absolute;
            width: @widthMenu;
            padding: 10px;
            background: @clrBG1;
            border-radius: 8px;
            z-index: @zIndexChrome;
            &.displayed {
                display: block;
            }
            .menu-item {
                .NonSelectable();
                display: block;
                position: relative;
                color: @clrFG1;
                margin: 0px auto;
                text-align: left;
                padding: 12px 12px 12px 50px;
                border-radius: 4px;
                font-weight: 700;
                font-size: 18px;
                outline: none;
                text-decoration: none;
                background: @clrWhiteAlpha0;
                .TransAllCubic(@transDurationVeryFast);
                &:hover, &:focus {
                    cursor: pointer;
                    background: @clrWhiteAlpha2;
                    outline: none;
                }
                &:before {
                    filter: @filterFG1;
                    display: inline-block;
                    position: absolute;
                    width: 43px;
                    height: 43px;
                    content: '';
                    left: 0px;
                    top: 0px;
                }
                &.upload {
                    &:before {
                        transform: rotate(90deg);
                        .BGimage('@{pathProd}img/icon_arrow_close.svg');
                    }
                }
                &.trash {
                    &:before {
                        .BGimage('@{pathProd}img/icon_trash.svg');
                    }
                }
            }
            &.menu-img-cover {
                right: 15px;
                top: calc(100% - 15px);
            }
            &.menu-img-profile {
                left: calc(100% - 45px);
                top: calc(100% - 18px);
            }
        }
        .panel-img-cover {
            position: relative;
            display: inline-block;
            width: @w;
            height: 320px;
            border-radius: 8px;
            border: 1px solid @clrBG3;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .btn-edit-profile {
                .NonSelectable();
                position: absolute;
                bottom: -60px;
                right: 15px;
                -webkit-appearance: none;
                appearance: none;
                display: inline-block;
                color: @clrFG1;
                text-align: left;
                padding: 0px 12px 0px 50px;
                background: @clrWhiteAlpha1;
                border: none;
                outline: none;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 700;
                width: 156px;
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                margin: 0px;
                .TransAllCubic(@transDurationVeryFast);
                transform: scale3d(1, 1, 1);
                &:before {
                    .TransAllCubic(@transDurationVeryFast);
                    display: inline-block;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    content: '';
                    left: 4px;
                    top: 0px;
                    .BGimage('@{pathProd}img/icon_pencil.svg');
                    filter: @filterFG1;
                    background-size: 85%;
                }
                &:hover, &:focus, &:active {
                    background: @clrWhiteAlpha3;
                    outline: none;
                    transform: scale3d(1.03, 1.03, 1.03);
                }
            }
            .btn-edit-cover {
                position: absolute;
                bottom: 15px;
                right: 15px;
                -webkit-appearance: none;
                appearance: none;
                display: inline-block;
                color: white;
                text-align: left;
                padding: 0px 12px 0px 50px;
                background: @clrWhiteAlpha1;
                border: none;
                outline: none;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 700;
                width: 156px;
                cursor: pointer;
                height: 45px;
                line-height: 45px;
                margin: 0px;
                .TextShadow();
                .DropShadow();
                .NonSelectable();
                .TransAllCubic(@transDurationVeryFast);
                transform: scale3d(1, 1, 1);
                &:before {
                    .TransAllCubic(@transDurationVeryFast);
                    display: inline-block;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    content: '';
                    left: 4px;
                    top: 3px;
                    .BGimage('@{pathProd}img/icon_camera.svg');
                    filter: @filterFG1;
                }
                &:hover, &:focus, &:active {
                    transform: scale3d(1.03, 1.03, 1.03);
                    background: @clrWhiteAlpha3;
                    outline: none;
                }
            }
        }
        .profile-img {
            display: inline-block;
            position: relative;
            border-radius: 50%;
            border: 3px solid @clrBlack;
            z-index: 2;
            width: 200px;
            height: 200px;
            margin-top: -150px;
            overflow: visible;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .btn-edit-profile-img {
                position: absolute;
                bottom: 9px;
                right: -11px;
                -webkit-appearance: none;
                appearance: none;
                display: inline-block;
                color: @clrFG1;
                padding: 0;
                background: @clrWhiteAlpha1;
                border: none;
                outline: none;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                cursor: pointer;
                .TextShadow();
                .DropShadow();
                .NonSelectable();
                .TransAllCubic(@transDurationVeryFast);
                transform: scale3d(1, 1, 1);
                &:before {
                    .TransAllCubic(@transDurationVeryFast);
                    transform: scale3d(1, 1, 1);
                    display: inline-block;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    content: '';
                    left: 2.5px;
                    top: 2.5px;
                    .BGimage('@{pathProd}img/icon_camera.svg');
                    filter: @filterFG1;
                }
                &:hover, &:focus, &:active {
                    transform: scale3d(1.03, 1.03, 1.03);
                    background: @clrWhiteAlpha3;
                    outline: none;
                    &:before {
                        transform: scale3d(1.03, 1.03, 1.03);
                    }
                }
            }
        }
        .input {
            width: @w;
            margin: 20px auto 10px auto;
            & ~ .input {
                margin: 10px auto;
            }
            input[type="text"] {
                width: 100%;
            }
        }
        textarea {
            width: @w;
            line-height: 30px;
            color: @clrFG1;
            font-size: 18px;
            background: @clrBG2;
            padding: 10px 20px;
            margin: 0px auto;
            border-radius: 6px;
            border: none;
            outline: none;
            &::placeholder {
                color: @clrPlaceholder;
            }
            &:focus, &:active {
                border: none;
                outline: none;
                color: @clrBG1;
                background: @clrFG1;
            }
        }
        .footer {
            width: @w;
            margin: 0px auto;
            text-align: right;
            .btn-save, .btn-cancel {
                .BtnDefault();
                margin-right: 0;
            }
        }
        .bar-social, .social-bar {
            display: inline-block;
            width: @w;
            margin: 0px auto;
            div.input.text.input-social-link {
                margin-top: 40px;
            }
        }
    }
    .schedule-panel {
        display: none;
        .grid-calendar {
            width: @w;
            margin: auto;
            display: grid;
            grid-template-rows: 36px 50px 100px 100px 100px 100px 100px 100px 100px;
            grid-template-columns: 96px auto auto auto auto auto auto auto auto auto;
            font: 400 12px Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif;
            .btn-calendar-today {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row: 1;
                .NonSelectable();
                position: absolute;
                -webkit-appearance: none;
                appearance: none;
                color: white;
                text-align: center;
                padding: 0px;
                background: @clrBG2;
                border: none;
                outline: none;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 700;
                width: 96px;
                cursor: pointer;
                height: 36px;
                line-height: 35px;
                margin: 0px;
                .TransAllCubic(@transDurationVeryFast);
                &:before {
                    .TransAllCubic(@transDurationVeryFast);
                    display: inline-block;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    content: '';
                    left: 0px;
                    top: 0px;
                }
                &:focus, &:active {
                    outline: none;
                }
                &:hover, &:focus {
                    background: @clrBG3;
                    outline: none;
                }
            }
            .grid-buttons {
                display: inline-grid;
                grid-column: 2/11;
                grid-row: 1;
                .grid-buttons2 {
                    display: inline-block;
                    .btn-calendar-left-arrow {
                        .NonSelectable();
                        display: inherit;
                        position: absolute;
                        -webkit-appearance: none;
                        appearance: none;
                        color: white;
                        padding: 0px;
                        background: rgba(255, 255, 255, 0.1);
                        border: none;
                        outline: none;
                        border-radius: 4px;
                        width: 40px;
                        cursor: pointer;
                        height: 36px;
                        margin: 0px 0px 0px 10px;
                        .TransAllCubic(@transDurationVeryFast);
                        &:before {
                            .TransAllCubic(@transDurationVeryFast);
                            position: absolute;
                            width: 40px;
                            height: 36px;
                            content: '';
                            top: 0px;
                            left: 0px;
                            .BGimage('@{pathProd}img/icon_arrow_left.svg');
                        }
                        &:focus, &:active {
                            outline: none;
                        }
                        &:hover, &:focus {
                            background: rgba(255, 255, 255, 0.3);
                            outline: none;
                        }
                    }
                    .btn-calendar-right-arrow {
                        .NonSelectable();
                        display: inherit;
                        position: absolute;
                        -webkit-appearance: none;
                        appearance: none;
                        color: white;
                        padding: 0px;
                        background: rgba(255, 255, 255, 0.1);
                        border: none;
                        outline: none;
                        border-radius: 4px;
                        width: 40px;
                        cursor: pointer;
                        height: 36px;
                        margin: 0px 0px 0px 55px;
                        .TransAllCubic(@transDurationVeryFast);
                        &:before {
                            .TransAllCubic(@transDurationVeryFast);
                            display: inline-block;
                            position: absolute;
                            width: 40px;
                            height: 36px;
                            content: '';
                            left: 0px;
                            top: 0px;
                            .BGimage('@{pathProd}img/icon_arrow_right.svg');
                        }
                        &:focus, &:active {
                            outline: none;
                        }
                        &:hover, &:focus {
                            background: rgba(255, 255, 255, 0.3);
                            outline: none;
                        }
                    }
                    .btn-calendar-calendar {
                        .NonSelectable();
                        display: inherit;
                        position: absolute;
                        -webkit-appearance: none;
                        appearance: none;
                        color: white;
                        padding: 0px;
                        background: rgba(255, 255, 255, 0.1);
                        border: none;
                        outline: none;
                        border-radius: 4px;
                        width: 40px;
                        cursor: pointer;
                        height: 36px;
                        margin: 0px 0px 0px 110px;
                        .TransAllCubic(@transDurationVeryFast);
                        &:before {
                            .TransAllCubic(@transDurationVeryFast);
                            display: inline-block;
                            position: absolute;
                            width: 40px;
                            height: 36px;
                            content: '';
                            left: 0px;
                            top: 0px;
                            .BGimage('@{pathProd}img/icon_calendar.png');
                        }
                        &:focus, &:active {
                            outline: none;
                        }
                        &:hover, &:focus {
                            background: rgba(255, 255, 255, 0.3);
                            outline: none;
                        }
                    }
                    p {
                        display: inherit;
                        position: absolute;
                        width: 285px;
                        font-size: 24px;
                        font-weight: 700;
                        margin: 7px 0px auto 165px;
                        overflow: auto;
                    }
                }
            }
            .row-mon, .row-tues, .row-wed, .row-thurs, .row-fri, .row-sat, .row-sun, .grid-time-zone, .grid-time-times {
                font: 400 18px Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif;
                background: @clrBG1;
                margin: 0px;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .row-mon, .row-tues, .row-wed, .row-thurs, .row-fri, .row-sat, .row-sun {
                font-size: 24px;
            }
            .grid-time-zone {
                background: @clrBG0;
                grid-column: 1;
                grid-row: 2;
            }
            .grid-time-times {
                background: @clrBG0;
                grid-column: 2/11;
                grid-row: 2;
            }
            .row-mon {
                grid-column: 1;
                grid-row: 3;
            }
            .row-tues {
                grid-column: 1;
                grid-row: 4;
            }
            .row-wed {
                grid-column: 1;
                grid-row: 5;
            }
            .row-thurs {
                grid-column: 1;
                grid-row: 6;
            }
            .row-fri {
                grid-column: 1;
                grid-row: 7;
            }
            .row-sat {
                grid-column: 1;
                grid-row: 8;
            }
            .row-sun {
                grid-column: 1;
                grid-row: 9;
            }
        }
    }
}
