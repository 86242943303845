@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-create-table {
   .Dialog( @widthDialog * 1.5 );
   text-align : center;
   .header {

   }
   .body {
      padding-top: 12px;
      color : @clrFG0;
      .input {
         input {
            .Input(100%);
         }
      }
      .input-tags {
         input {
            .Input(100%);
            margin-bottom : 3px;
         }
      }
      label {
         color : @clrFG0;
         transform : none;
         font-size : 20px;
         margin-top : -8px;
      }
      .date-pickers {
         display: flex;
         justify-content: space-between;
         padding : 15px 0px;
         input[type=text] {
            height: unset;
            margin-bottom : 1px;
         }
         .MuiInput-underline {
            &:before {
               border-bottom : 0 !important;
            }
         }
      }
      .duration {
         text-align : left;
         position : relative;
         height : 90px;
         margin-top: 8px;
         .input {
            display : inline-block;
            width : 60px;
            white-space: nowrap;
            input {
               text-align : center;
               padding-left : 2px;
               padding-right : 2px;
            }
            &:before {
               content : 'duration';
               display : block;
               height: 24px;
               line-height: 24px;
               position: absolute;
               top: -12px;
            }
            &:after {
               content : 'hrs';
               display : inline-block;
               line-height : 75px;
               padding-left : 5px;
            }
         }
         .input-checkbox {
            display : inline-block;
            margin-left : 50px;
            .NonSelectable();
         }
      }
      .donation {
         text-align : left;
         position : relative;
         height : 90px;
         .input {
            display : inline-block;
            width : 60px;
            white-space: nowrap;
            input {
               text-align : center;
               padding-left : 2px;
               padding-right : 2px;
            }
            &:before {
               content : 'donation';
               display : block;
               height: 24px;
               line-height: 24px;
               position: absolute;
               top: -12px;
            }
            &:after {
               content : 'mojo';
               display : inline-block;
               line-height : 75px;
               padding-left : 5px;
            }
         }
      }
      .invites {
         text-align : left;
         position : relative;
         margin-top : 10px;
         &:before {
            content : 'invite';
            display : block;
            height: 24px;
            line-height: 24px;
            position: absolute;
            top: -25px;
         }
      }
  }
   .footer {

   }
}


