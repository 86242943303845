@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@yellow: #efc369;
@purple: #a494c0;
@orange: #d7925f;
@green: #b5bc67;
@offBlack: #1d1f20;
@lightBlue: #87cefa;

@arrowBtnDims: 45px;
@dimTile: 240px;
@gutter: 15px;
@widthStage: 780px;

.carousel {
    position: relative;
    width : @widthStage;
    margin: 20px auto;
    .btn-arrow {
        user-select: none;
        cursor: pointer;
        position: absolute;
        top: calc(50% - (@arrowBtnDims * 0.5));
        display: inline-block;
        width: @arrowBtnDims;
        height: @arrowBtnDims;
        font-size: 30px;
        line-height: @arrowBtnDims;
        font-weight: 900;
        color: black;
        z-index: 10;
        text-align: center;
        border-radius: 50%;
        background: @clrBlackAlpha0;
        .TransAllCubic( @transDurationVeryFast );
        &:hover {
            background : @clrBG3;
        }
        &:before {
            content : '';
            position : absolute;
            width : 100%;
            height : 100%;
            top : 0px;
            left : 0px;
            filter : @filterFG0;
        }
        &.left {
            left: -( @arrowBtnDims * 0.5 );
            &:before {
                .BGimage( '@{pathProd}img/icon_chevron_left.svg' );
                background-size : 60%;
            }
        }
        &.right {
            right: -( @arrowBtnDims * 0.5 );
            &:before {
                .BGimage( '@{pathProd}img/icon_chevron_right.svg' );
                background-size : 60%;
            }
        }
    }
    .stage {
        border-radius : 8px;
        position: relative;
        box-sizing: border-box;
        // background: #333642;
        // border: 2px solid @purple;
        height: 500px;
        width: @widthStage; // + 40px;
        margin: 20px auto;
        overflow: hidden;
        padding: 0;
        .tile {
            background : @clrBG1;
            box-shadow: 4px 4px 6px 1px rgba(0, 0, 0, 0.3);
            user-select: none;
            position: absolute;
            height: @dimTile * 1.618;
            width: @dimTile;
            top: 0;
            bottom: 0;
            text-align: center;
            border-radius: 10px;
            .TransAllCubic( @transDurationFast );
            transform: scale3d(0.8, 0.8, 0.8);
            margin: auto 0;
            overflow: hidden;
            * {
                pointer-events: none;
            }
            &.stage-center {
                left: calc(50% - (@dimTile * 0.5));
                display: inline-block;
                transform: scale3d(1.25, 1.25, 1.25);
                opacity: 1;
                * {
                    pointer-events: all;
                }
            }
            &.stage-left {
                left: @gutter;
                display: inline-block;
                opacity: 0.8;
            }
            &.stage-right {
                left: calc(100% - @dimTile - @gutter);
                display: inline-block;
                opacity: 0.8;
            }
            &.offstage-left {
                left: -(@dimTile + @gutter);
                display: inline-block;
                opacity: 0;
                transform: scale3d(0.5, 0.5, 0.5);
            }
            &.offstage-right {
                left: calc(100% + @gutter);
                display: inline-block;
                opacity: 0;
                transform: scale3d(0.5, 0.5, 0.5);
            }
        }
    }
}
.no-transition {
    .TransNone();
}
