@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@arrowDims: 60px;
@dimTile : 400px;

.dialog-orientation {
  .Dialog ( @widthDialog * 1.25 );
  padding: 25px 60px 90px;
  text-align : left;
  .body{
    h3 {
      font-size : 24px;
      margin-top: 17px;
      margin-bottom: -6px;
      color: @clrYellow;
    }
    p {
      font-size : 17px;
      font-weight : 600;
      line-height: 27px;
      strong{
        color: @clrYellow;
      }
    }
  }
}
