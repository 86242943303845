@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-overlay {
   .Panel();
      z-index : @zIndexChrome;
      background : rgba( 0, 0, 0, 0.4 );

   &.spinner {
      z-index: @zIndexChromeAbove;
   }

   &.dark {
      background : rgba( 0, 0, 0, 0.8 );
   }
}


