@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@heightBar: 60px;
@gap: 10px;

.FromRight( @place : 1 ){
    right: ( ( @btnDimHUD + @gap ) * @place ) + @gap;
}

.FromLeft( @place : 1 ){
    left: ( ( @btnDimHUD + @gap ) * @place ) + @gap;
}

.bar-polycast {
    position: absolute;
    z-index: @zIndexChrome;
    bottom: -@heightBar;
    background: @clrBG1;
    height: @heightBar;
    white-space: nowrap;
    width: 100%;
    border : @hairline;
    border-top: 2px solid @clrFG0;
    padding: 0px;
    overflow: visible;
    .TransAllCubic(@transDurationFast);
    .menu {
        .DropShadow();
        &.menu-people {
            .FromLeft( 2.5 );
            bottom : calc( 100% + 5px );
        }
    }
    button {
        .NonSelectable();
        .TransAllCubic(@transDurationVeryFast);
        transform: scale(0.95);
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        display: inline-block;
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto 0px;
        cursor: pointer;
        border: none;
        border-radius: 10px;
        background : @clrBlackAlpha0;
        width: @btnDimHUD;
        height: @btnDimHUD;
        &:before {
            filter : @filterFG1;
            content: '';
            display: inline-block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: @btnDimHUD;
            height: @btnDimHUD;
        }
        &:hover {
            background: @clrBG3;
            outline: none;
        }
        //region icons
        &.icon-leave {
            left: @gap;
            width: @btnDimHUD * 1.5;
            &:before {
                width: @btnDimHUD * 1.5;
                .BGimage('@{pathProd}img/icon_polycast_leave.svg');
                background-size: 85%;
            }
        }
        &.icon-cogwheel {
            .FromLeft( 1.5 );
            &:before {
                .BGimage('@{pathProd}img/icon_cogwheel.svg');
            }
        }
        &.icon-people {
            .FromLeft( 2.5 );
            width: @btnDimHUD * 1.5;
            &:before {
                width: @btnDimHUD * 1.5;
                .BGimage('@{pathProd}img/icon_people.svg');
                background-size: 69%;
            }
        }
        &.icon-add-person {
            .FromLeft( 4 );
            &:before {
                width: @btnDimHUD * 1.5;
                .BGimage('@{pathProd}img/icon_user_plus.svg');
                background-size: 85%;
                left: -10px;
            }
        }
        &.icon-speaker {
            .FromRight( 1 );
            &:before {
                .BGimage('@{pathProd}img/icon_speaker.svg');
            }
            &.off {
                &:before {
                    .BGimage('@{pathProd}img/icon_speaker_off.svg');
                }
            }
        }
        &.icon-fullscreen {
            right: @gap;
            &:before {
                .BGimage('@{pathProd}img/icon_fullscreen.svg');
            }
            &.off {
                &:before {
                    .BGimage('@{pathProd}img/icon_fullscreen_off.svg');
                }
            }
        }
        //endregion
    }
}

.panel-polycast {
    &:fullscreen, :-webkit-full-screen {
        .bar-polycast {
            bottom: 0px;
            height: 8px;
            opacity : 0;
            &:hover {
                height: @heightBar;
                opacity : 1;
            }
        }
    }
}
