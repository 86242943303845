@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.input {
   display : none;
   .asterisk{
      position: relative;
      display: inline-block;
      width: 0;
      font-size: 27px;
      &.red {
         color : @clrError;
      }
      &:before{
         content: "*";
         position: absolute;
         left: -20px;
         top: -8px;
         line-height: 1px;
      }
   }

   input[type="text"], input[type="datetime-local"] {
      .Input();
      margin-bottom : 5px;
   }

   &.chat {
      input[type="text"], input[type="datetime-local"] {
         border-radius : 0px;
      }
   }

   .error-msg {
      width: 90%;
      font-weight: 600;
      color : @clrError;
      font-size: 14px;
      margin: 0px auto 10px auto;
      text-align: left;
      height : 25px;
      line-height : 25px;
      &:empty {
         height : 0px;
         line-height : 0px;
      }
   }

   &.displayed {
      display : block;
      &.date, &.time {
         display : inline-block;
      }
   }
}
