@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-event {
    .Panel();
    padding : 50px;
    text-align : center;
    h2, h3, h4 {
        text-align: center;
        max-width: 100%;
        margin: 20px auto;
        &.left {
            text-align: left;
        }
    }
    h2 {
        text-align : left;
        color : @clrYellow;
        font-size: 36px;
        margin : 0px auto;
        font-weight : 700;
    }
    h3 {
        font-size: 20px;
        margin : 0px auto;
    }
    h4 {
        font-size: 19px;
        margin-top : 10px;
        text-align : left;
    }
    pre {
        border : 1px solid @clrFG0;
        padding : 5px;
        margin : 20px;
        background : @clrBG1;
        font-family : courier new, serif;
        font-weight : 600;
        overflow : hidden;
        text-align : left;
    }
    .timer{
        display : none;
        white-space: pre;
        text-align : left;
    }
    .footer {
        width: 820px;
        text-align : right;
        max-width: 100%;
        padding : 30px 0px;
        display : inline-block;
    }
}

