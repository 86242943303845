@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@bgSizeSocialBtn : contain;

.social-bar {
   .bar-social {
      &.is-editable {
         transform : scale( 1.4 );
         margin-bottom: 20px;
      }
      .social-link {
         display : inline-block;
         position : relative;
         width : @heightBtn;
         height : @heightBtn;
         margin-right : 3px;
         outline : none;
         background-color : transparent;
         color : @clrBlackAlpha0;
         font-size : 0px;
         line-height : 0px;
         &:after {
            filter : @filterWhite;
            position : absolute;
            content : '';
            left: 9px;
            top : 9px;
            width : @heightBtn - 18px;
            height : @heightBtn - 18px;
            .TransAllCubic( @transDurationVeryFast );
         }
         &:hover, &:focus, &.being-edited {
            cursor : pointer;
            &:after {
               filter: @filterBrand;
               transform : scale3d( 1.2, 1.2, 1.2 );
               background: none;
            }
         }
         &:active {
            outline : none;
         }

         &:not([href]), [href=""] {
            opacity : 0.5;
         }
         &.twitter {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_twitter.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.facebook {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_facebook.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.youtube {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_youtube.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.soundcloud {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_soundcloud.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.spotify {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_spotify.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.instagram {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_instagram.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.twitch {
            &:after {
               .BGimage( '@{pathProd}img/mask_social_twitch.svg' );
               background-size : @bgSizeSocialBtn;
            }
         }
         &.being-edited {
            &:before {
               .ArrowDown( @clrBrand );
            }
         }
      }
   }

   .input-social-link {
      display : none;
      &.displayed {
         display : unset;
      }
   }
}
