@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.slider {
   .CoordsStreamPanel();
   position : absolute;
   width : 10px;
   padding : 0px;
   margin : 0px;
   overflow : visible;
   filter : @filterFG1;
   &.above {
      transform: translate(14px, -39px);
   }
   .track-top {
      position : absolute;
      top : 0px;
      left : 0px;
      width : 10px;
      height : 10px;
      .BGimage( '@{pathProd}img/slider_track_top.svg' );
   }
   .track-middle {
      position : absolute;
      top : 10px;
      left : 0px;
      width : 10px;
      bottom : 10px;
      height : calc( 100% - 20px );
      .BGimage( '@{pathProd}img/slider_track_middle.svg' );
      background-position : top;
      background-repeat: repeat-y;
      background-size: contain;
   }
   .track-bottom {
      position : absolute;
      bottom : 0px;
      left : 0px;
      width : 10px;
      height : 10px;
      .BGimage( '@{pathProd}img/slider_track_bottom.svg' );
   }
   .slider-handle {
      position : absolute;
      top : calc( 100% - 8px );
      left : -6px;
      margin : auto 0px;
      width : 22px;
      height : 8px;
      z-index : 1;
      .BGimage( '@{pathProd}img/slider_handle.svg' );
   }
}
