@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.menu {
   position: absolute;
   width: @widthMenu;
   padding: 10px;
   background: @clrBG1;
   border-radius: 8px;
   z-index: @zIndexChrome;
   text-align : center;
}
