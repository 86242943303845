@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.app {
    .main {
        background : @clrBG0;
        position : absolute;
        z-index : 0;//@zIndexChrome;
        width : calc( 100vw - @widthActivityMenuDismissed);
        left : @widthActivityMenuDismissed;
        top : @heightTopBar;
        right : @widthSidePanel;
        bottom : 0px;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        scroll-behavior: smooth;
        overflow-x : visible;
        overflow-y : visible;
        -webkit-transform: translate3d( 0, 0, 0 );
        transform: translate3d( 0, 0, 0 );
        .TransAllCubic( @transDurationVeryFast );
        &::-webkit-scrollbar {
            display : none;
        }
        &.scrollable {
            overflow-y : scroll;
        }
        &.chat {
            width : calc( 100vw - @widthActivityMenuDismissed - @widthSidePanel);
        }
        @media @querySmall {
            top : @heightTopBarSmall;
            left : 0px;
            width : 100vw;
            &.chat {
                // TODO : likely want a different layout for chat on mobile
                width : calc( 100vw - @widthSidePanel);
            }
        }
        @media @queryMedium {
            top : @heightTopBarSmall;
        }
    }
    audio {
        display : none;
    }
    #dustbin {
        display : none;
    }
}
