@import "../../../css/vars.less";
@import "../../../css/mixins.less";


.panel-activity {
   .Panel();
   // .RainbowBorder( 1px );
   border : 1px solid grey;
   padding : 0;
}
