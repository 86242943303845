@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-ver-code {
    .Dialog( @widthDialog );
    padding: 88px 60px 90px;
    .body {
        padding : 0px;
        min-height : unset;
    }
    .footer {
        text-align : right;
    }
}
