@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.ButtonOverrides() {
  box-shadow: none !important;
  background : transparent !important;
  border : 1px solid rgba( 0, 0, 0, 0 ) !important;
}

.btn-vibe {
  &.btn-txt {
    .ButtonOverrides();
    &:disabled, &.disabled {
      .ButtonOverrides()
    }
    &:hover {
      .ButtonOverrides()
    }
    .MuiButton-label {
      text-decoration : underline;
    }
  }
  &:disabled, &.disabled {
    background: @clrDisabledBG;
    border : 1px solid @clrDisabled;
    .MuiButton-label {
      color: @clrDisabled;
    }
  }
  .MuiButton-label {
    text-transform : none;
    .TextShadow();
  }
}
