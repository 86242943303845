@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-club {
   .Panel();
   text-align : center;
   max-width : 100%;
   padding : 50px;
   .panel-profile-club {

   }
}
