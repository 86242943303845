@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@w: 820px;

.panel-club-view {
    text-align: center;

    h2, h3, h4 {
        text-align: center;
        width: @w;
        max-width: 100%;
        margin: 20px auto;
        &.left {
            text-align: left;
        }
    }
    h2 {
        color : @clrYellow;
        font-size: 36px;
        margin : 0px auto;
        font-weight : 700;
    }
    h3 {
        font-size: 20px;
        margin : 0px auto;
    }
    h4 {
        font-size: 19px;
        margin-top : 10px;
        text-align : left;
    }

    p {
        width: @w;
        max-width: 100%;
        margin: 0 auto;
        &.left {
            text-align: left;
        }
    }
    .imgs {
        display : inline-block;
        position : relative;
        width: @w;
        .cover {
            position: relative;
            display: inline-block;
            width: @w;
            max-width: 100%;
            height: 320px;
            border-radius: 8px;
            border: 1px solid @clrBG3;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .profile {
            display: inline-block;
            position: relative;
            border-radius: 50%;
            border: 3px solid @clrBlack;
            z-index: 2;
            width: 200px;
            height: 200px;
            margin-top: -150px;
            overflow: visible;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .tags {
        position : relative;
        margin-top : 30px;
        &:before {
            content : 'tags : ';
            .NonSelectable();
            height: 25px;
            line-height: 25px;
            background: transparent;
            position : relative;
            font-size : 16px;
            padding : 0px 10px 0px 0px;
            border-radius: 6px;
            display: inline-block;
            margin: 0 5px 0 0;
            overflow : hidden;
            outline: none;
            font-weight: 800;
        }
        .tag {
            .NonSelectable();
            height: 25px;
            line-height: 25px;
            background: @clrBG2;
            position : relative;
            font-size : 16px;
            padding : 0px 25px;
            border-radius: 6px;
            display: inline-block;
            margin: 0 5px 0 0;
            overflow : hidden;
            outline: none;
        }
    }
}
