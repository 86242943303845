@import "vars.less";
.HardwareAccelerate() {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.FullScreen() {
  position: absolute;
  margin: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.RainbowBorder ( @w: 5px ) {
  border: @w solid transparent;
  border-image: linear-gradient(to bottom right, #B827FC 0%, #2C90FC 25%, #B8FD33 50%, #FEC837 75%, #FD1892 100%);
  border-image-slice: 1;
}
.Dims( @w, @h ) {
  width: @w;
  height: @h;
}
.FlipVertical() {
  transform: rotate(180deg);
}
.FlipHorizontal() {
  transform: scaleX(-1);
}
.FlipHorizontalNot() {
  transform: scaleX(1);
}
.BGimage() {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.BGimage( @url ) {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(@url);
}
.Centered( @position : fixed ) {
  position: @position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Dialog( @w : @widthDialog ) {
  .Centered();
  border-radius: 8px;
  background: @clrBG0;
  .DropShadow();
  text-align: center;
  padding: 50px 60px 90px 60px;
  width: @w;
  max-width: 100%;
  .header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: @dimBtnClose + 10px;
    .title {
      color: @clrYellow;
      font-weight: 900;
      font-size: 26px;
      text-align: left;
      padding: 25px 0px 30px 25px;
    }
    .btn-close {
      background: transparent;
      outline: none;
      box-shadow: none;
      border: none;
      border-radius: 50%;
      width: @dimBtnClose;
      height: @dimBtnClose;
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 7px;
      color: @clrFG1;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: @dimBtnClose;
        height: @dimBtnClose;
        content: '';
        .BGimage('@{pathProd}img/icon_menu_close.svg');
        filter: @filterFG1;
        background-size: 66%;
      }
      &:hover, &:focus, &:active {
        outline: none;
        cursor: pointer;
        background: @clrBG3;
        border: none;
      }
    }
  }
  .body {
    padding-top: 37px;
    min-height: 200px;
    width: 100%;
  }
  .footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    text-align: right;
    padding: 0px 10px;
    button {
      .BtnDefault();
    }
  }
}

.Panel( @padding:15px ) {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  margin: 0 auto;
  background: @clrBG0;
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - @heightTopBar);
  padding: @padding;
  color: white;
  h1 {
    margin: 0;
    font-size: 45px;
  }
  h3 {
    font-size: 26px;
  }
  p {
    margin: 10px 0px;
    font-weight: 400;
    font-size: 16px;
  }
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .btn {
    .BtnDefault();
    margin-left: 0px;
  }
  @media @querySmall {
    height: calc(100vh - @heightTopBarSmall);
  }
  @media @queryMedium {
    height: calc(100vh - @heightTopBarSmall);
  }
}

.SubPanel() {
  position: relative;
  border-radius: 6px;
  border: 1px solid @clrWhiteAlpha3;
  background: @clrWhiteAlpha1;
  padding: 15px;
  width: 100%;
  display: table;
  table-layout: fixed;
  max-width: 1000px;
  &.has-footer {
    padding-bottom: 100px;
  }
  .table-row {
    display: table-row;
    width: 100%;
    &.hr {
      background: green;
      height: 2px;
    }
    .table-cell {
      position: relative;
      display: table-cell;
      vertical-align: top;
      width: 100%;
      padding: 15px;
      &.hr {
        line-height: 0;
        padding: 0;
        height: 10px;
        border-bottom: 1px solid @clrWhiteAlpha3;
      }
      &.middle {
        vertical-align: middle;
      }
      &.short {
        width: 15%;
        label {
          line-height: 50px;
          font-weight: 600;
        }
      }
      &.long {
        width: 85%;
      }
      &.quarter {
        width: 25%;
      }
      &.half {
        width: 50%;
      }
      input[type=text] {
        width: 100%;
        margin-bottom: 0;
      }
      textarea {
        width: 100%;
        padding: 10px;
        font-size: 18px;
        background: @clrBG2;
        // border : 2px solid @clrBG1;
        border-radius: 6px;
      }
      button {
        .BtnDefault();
        margin-left: 0px;
      }
    }
  }
  .sub-panel-footer {
    display: table-cell;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: 87px;
    text-align: right;
    background: @clrWhiteAlpha2;
    border-radius: 0 0 6px 6px;
    //margin-bottom : -@paddingSubPanel;
    button {
      .BtnDefault();
      position: absolute;
      right: 21px;
      top: 0px;
      bottom: 0px;
      margin: auto 0;
    }
  }
}
.ArrowDown( @clr: @clrFG1 ) {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid @clr;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 5px);
}
.Input( @w: 90% ) {
  font: 400 18px Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
  appearance: none;
  width: @w;
  height: @heightInputText;
  line-height: 30px;
  padding: 10px 20px;
  background: @clrBG2;
  border: none;
  border-radius: 6px;
  color: @clrFG1;
  margin-bottom: 20px;
  &::placeholder {
    color: @clrPlaceholder;
  }
  &:focus, &:active {
    outline: none;
    color: @clrBG1;
    background: @clrFG1;
    border: none;
    border-radius: 6px;
  }
  &.error {
    border: 3px solid @clrError;
  }
}
.SmoothFonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.StageContent() {
  display: none;
  position: relative;
  padding: 10px;
  .SmoothFonts();
  &.displayed {
    display: block;
  }
}
.TextShadow( @spread : 1px ) {
  text-shadow: @spread @spread 2px @clrBlackAlpha3;
}
.DropShadow() {
  box-shadow: 4px 4px 6px 1px @clrBlackAlpha3;
}

//region GlowLight
.GlowLight(){
  &.red {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #F88A93 )
    drop-shadow( 0px 0px 30px #F65C6A )
    drop-shadow( 0px 0px 50px #F3293D );
  }
  &.orange {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #FBCBA0 )
    drop-shadow( 0px 0px 30px #FAB57B )
    drop-shadow( 0px 0px 50px #F99F53 );
  }
  &.yellow {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #FDFCA6 )
    drop-shadow( 0px 0px 30px #FCFA84 )
    drop-shadow( 0px 0px 50px #FCFA61 );
  }
  &.green {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #A5FBAD )
    drop-shadow( 0px 0px 30px #83FA8E )
    drop-shadow( 0px 0px 50px #5DF96B );
  }
  &.blue {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #9DF0FB )
    drop-shadow( 0px 0px 30px #77EAFB )
    drop-shadow( 0px 0px 50px #4FE4F9 );
  }
  &.indigo {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #989CC8 )
    drop-shadow( 0px 0px 30px #7075B3 )
    drop-shadow( 0px 0px 50px #434A9B );
  }
  &.violet {
    filter: drop-shadow( 0px 0px 1px #ffffff32 )
    drop-shadow( 0px 0px 3px #ffffff3c  )
    drop-shadow( 0px 0px 10px #DB9AF8 )
    drop-shadow( 0px 0px 30px #CD72F6 )
    drop-shadow( 0px 0px 50px #BE49F4 );
  }

}

//endregion

.DropShadowInner() {
  box-shadow: inset 0 0 10px @clrBlackAlpha1;
}
.BtnDefault() {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  margin: 10px;
  padding: 0px 25px;
  color: @clrFG1;
  font-weight: 700;
  background: @clrBtnBG;
  border: none;
  outline: none;
  .TextShadow();
  .NonSelectable();
  .TransAllCubic(@transUltraFast);
  // transform: scale3d( 1, 1, 1 );
  &:disabled, &.disabled {
    pointer-events: none;
    background: @clrDisabledBG;
    color: @clrDisabled;
  }
  &:hover, &:focus {
    // transform: scale3d( 1.03, 1.03, 1.03 );
    outline: none;
    cursor: pointer;
    background: @clrBtnHoverBG;
    border: none;
  }
  &:active {
    outline: none;
    cursor: pointer;
    padding-top: 2px;
    border: none;
  }
}
.NonSelectable() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// TODO : HeaderBtn may be unused - delete?
.HeaderBtn() {
  position: absolute;
  right: 0px;
  width: 60px;
  height: 58px;
  margin: 0px;
  font-size: 25px;
  line-height: 60px;
  text-align: center;
  display: none;
  &.displayed {
    display: block;
  }
}
.Translate( @x, @y, @z ) {
  transform: translate3d(@x, @y, @z);
  -webkit-transform: translate3d(@x, @y, @z);
}

.Transition( @props : all, @dur : 1s, @ease: ease-in-out, @delay : 0s ) {
  -webkit-transition: @props @dur @ease @delay;
  -moz-transition: @props @dur @ease @delay;
  -o-transition: @props @dur @ease @delay;
  transition: @props @dur @ease @delay;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.TransAll( @dur ) {
  -webkit-transition: all @dur;
  -moz-transition: all @dur;
  -o-transition: all @dur;
  transition: all @dur;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.TransNone() {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  transition: none !important;
  transition-property: none !important;
}
.TransAllCubic( @dur ) {
  -webkit-transition: all @dur cubic-bezier(0.7, 0.01, 0.3, 1);
  -moz-transition: all @dur cubic-bezier(0.7, 0.01, 0.3, 1);
  -o-transition: all @dur cubic-bezier(0.7, 0.01, 0.3, 1);
  transition: all @dur cubic-bezier(0.7, 0.01, 0.3, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.TransRotate( @deg ) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}
.TransRotateY( @deg ) {
  -webkit-transform: rotateY(@deg);
  -moz-transform: rotateY(@deg);
  -ms-transform: rotateY(@deg);
  transform: rotateY(@deg);
}
.CoordsStreamPanel() {
  &[data-coord^="a"] {
    left: 0px;
  }
  &[data-coord^="b"] {
    left: @btnDimHUD;
  }
  &[data-coord^="c"] {
    left: @btnDimHUD * 2;
  }
  &[data-coord^="d"] {
    right: @btnDimHUD * 3;
  }
  &[data-coord^="e"] {
    right: 0px;
  }
  &[data-coord*="1"] {
    top: 0px;
  }
  &[data-coord*="3"] {
    bottom: 0px;
  }
}

.PosterSmall{
  .poster {
    &:before {
      .BGimage( '@{pathProd}img/vibe_poster_small.svg' );
    }
  }
}

.PosterMedium {
  .poster {
    &:before {
      .BGimage( '@{pathProd}img/vibe_poster_medium.svg' );
    }
  }
}

.SendMenuBottom() {
  .menu-send {
    top : unset;
    bottom : -@heightSendMenu;
  }
}

.SendMenuLeft() {
  .menu-send {
    left : -140px;
  }
}

.SendMenuSubMenuBottom() {
  .menu-send-item {
    .menu-hearts {
      bottom : unset;
      top : @heightSendMenu;
      border-radius: 0px 0px 0px @borderRadius;
    }
  }
}

.Coords() {
  &[data-coord^="a"] {
    left: 0px;
    .PosterSmall();
  }
  &[data-coord^="b"] {
    left: @cellDim;
    .PosterSmall();
  }
  &[data-coord^="c"] {
    left: @cellDim * 2;
    .PosterSmall();
  }
  &[data-coord^="d"] {
    left: @cellDim * 3;
    .PosterSmall();
  }
  &[data-coord^="e"] {
    left: @cellDim * 4;
    .PosterSmall();
    .SendMenuLeft();
  }
  &[data-coord*="1"] {
    top: 0px;
    .SendMenuBottom();
    .SendMenuSubMenuBottom();
  }
  &[data-coord*="2"] {
    top: @cellDim;
    .SendMenuBottom();
    .SendMenuSubMenuBottom();
  }
  &[data-coord*="3"] {
    top: @cellDim * 2;
  }
  &[data-coord*="4"] {
    top: @cellDim * 3;
  }
  &[data-coord*="5"] {
    top: @cellDim * 4;
  }
  &[data-coord="stage-center"] {
    top: @cellDim;
    left: @cellDim;
    width: @cellDim * 3;
    height: @cellDim * 3;
  }
  &[data-coord="stage-tl"] {
    top: @cellDim;
    left: @cellDim;
    width: @cellDim * 1.5;
    height: @cellDim * 1.5;
    .PosterMedium();
  }
  &[data-coord="stage-tr"] {
    top: @cellDim;
    left: @cellDim * 2.5;
    width: @cellDim * 1.5;
    height: @cellDim * 1.5;
    .PosterMedium();
  }
  &[data-coord="stage-bl"] {
    top: @cellDim * 2.5;
    left: @cellDim;
    width: @cellDim * 1.5;
    height: @cellDim * 1.5;
    .PosterMedium();
  }
  &[data-coord="stage-br"] {
    top: @cellDim * 2.5;
    left: @cellDim * 2.5;
    width: @cellDim * 1.5;
    height: @cellDim * 1.5;
    .PosterMedium();
  }
}
.CoordsChildren( @offset : 2px ) {
  &[data-coord*="1"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-left: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }
     */
    .chat-panel-mini {
      top: calc(100% + @offset);
      left: @offset;
    }
  }
  &[data-coord*="2"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-left: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      top: @offset;
      left: @offset;
    }
  }
  &[data-coord*="3"] {
    .chat-panel-mini {
      top: @offset;
      left: @offset;
    }
  }
  &[data-coord*="4"] {
    /*
    .stream-panel-overlay {
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-left: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      bottom: 100%;
      left: @offset;
    }
  }
  &[data-coord*="5"] {
    /*
    .stream-panel-overlay {
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-left: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      bottom: 100%;
      left: @offset;
    }
  }
  &[data-coord="stage-center"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      width: 0px;
      top: -@offset;
      right: calc(100% + @offset);
      height: calc(100% + @offset);
      &.displayed {
        width: calc(33% + @offset);
      }
    }
  }
  &[data-coord="stage-tl"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      width: 0px;
      top: -@offset;
      right: 100%;
      height: 175%;
      &.displayed {
        width: 67%;
      }
    }
  }
  &[data-coord="stage-tr"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-left: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      width: 0px;
      top: -@offset;
      left: 100%;
      height: 175%;
      &.displayed {
        width: 67%;
      }
    }
  }
  &[data-coord="stage-bl"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }*/
    .chat-panel-mini {
      width: 0px;
      bottom: -@offset;
      right: 100%;
      height: 175%;
      &.displayed {
        width: calc(67% + @offset);
      }
    }
  }
  &[data-coord="stage-br"] {
    /*
    .stream-panel-overlay {
      border-top: 3px solid rgba(255, 255, 255, 0);
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      border-left: 3px solid rgba(255, 255, 255, 0);
    }
    */
    .chat-panel-mini {
      width: 0px;
      bottom: -@offset;
      left: 100%;
      height: 175%;
      &.displayed {
        width: 67%;
      }
    }
  }
}
@trans: ~'0.6s cubic-bezier(0.7, 0.01, 0.3, 1)';
.PulseBorder() {
  -webkit-transition: border @trans, box-shadow @trans;
  -moz-transition: border @trans, box-shadow @trans;
  -o-transition: border @trans, box-shadow @trans;
  transition: border @trans, box-shadow @trans;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 3px solid rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
  &.red {
    border: 3px solid rgb(249, 29, 42);
    box-shadow: 0px 0px 20px 15px rgba(218, 50, 37, 0.3);
  }
  &.orange {
    border: 3px solid rgb(253, 159, 60);
    box-shadow: 0px 0px 20px 15px rgba(253, 159, 60, 0.3);
  }
  &.yellow {
    border: 3px solid rgb(252, 254, 59);
    box-shadow: 0px 0px 20px 15px rgba(252, 254, 59, 0.3);
  }
  &.green {
    border: 3px solid rgb(71, 253, 87);
    box-shadow: 0px 0px 20px 15px rgba(71, 253, 87, 0.3);
  }
  &.blue {
    border: 3px solid rgb(52, 228, 253);
    box-shadow: 0px 0px 20px 15px rgba(52, 228, 253, 0.3);
  }
  &.indigo {
    border: 3px solid rgb(62, 66, 158);
    box-shadow: 0px 0px 20px 15px rgba(62, 66, 158, 0.3);
  }
  &.violet {
    border: 3px solid rgb(192, 46, 251);
    box-shadow: 0px 0px 20px 15px rgba(192, 46, 251, 0.3);
  }
}
.ClrBorderSide() {
  -webkit-transition: border @trans;
  -moz-transition: border @trans;
  -o-transition: border @trans;
  transition: border @trans;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 3px solid rgba(255, 255, 255, 0);
  box-shadow: unset;
  &.red {
    border: 3px solid rgb(249, 29, 42);
    box-shadow: unset;
  }
  &.orange {
    border: 3px solid rgb(253, 159, 60);
    box-shadow: unset;
  }
  &.yellow {
    border: 3px solid rgb(252, 254, 59);
    box-shadow: unset;
  }
  &.green {
    border: 3px solid rgb(71, 253, 87);
    box-shadow: unset;
  }
  &.blue {
    border: 3px solid rgb(52, 228, 253);
    box-shadow: unset;
  }
  &.indigo {
    border: 3px solid rgb(62, 66, 158);
    box-shadow: unset;
  }
  &.violet {
    border: 3px solid rgb(192, 46, 251);
    box-shadow: unset;
  }
}
.ClrBG() {
  -webkit-transition: background-color @trans, box-shadow @trans;
  -moz-transition: background-color @trans, box-shadow @trans;
  -o-transition: background-color @trans, box-shadow @trans;
  transition: background-color @trans, box-shadow @trans;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: rgba(255, 255, 255, 0);
  &.red {
    background-color: @clrBGred;
  }
  &.orange {
    background-color: @clrBGorange;
  }
  &.yellow {
    background-color: @clrBGyellow;
  }
  &.green {
    background-color: @clrBGgreen;
  }
  &.blue {
    background-color: @clrBGblue;
  }
  &.indigo {
    background-color: @clrBGindigo;
  }
  &.violet {
    background-color: @clrBGviolet;
  }
}
.ClrBorder() {
  -webkit-transition: border @trans, box-shadow @trans;
  -moz-transition: border @trans, box-shadow @trans;
  -o-transition: border @trans, box-shadow @trans;
  transition: border @trans, box-shadow @trans;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 3px solid rgba(255, 255, 255, 0);
  &.red {
    border: 3px solid rgb(249, 29, 42);
  }
  &.orange {
    border: 3px solid rgb(253, 159, 60);
  }
  &.yellow {
    border: 3px solid rgb(252, 254, 59);
  }
  &.green {
    border: 3px solid rgb(71, 253, 87);
  }
  &.blue {
    border: 3px solid rgb(52, 228, 253);
  }
  &.indigo {
    border: 3px solid rgb(62, 66, 158);
  }
  &.violet {
    border: 3px solid rgb(192, 46, 251);
  }
}
