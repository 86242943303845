@import "../../../css/vars.less";
@import "../../../css/mixins.less";


.settings-tabs {
   height : 40px;
   width : 100%;
   white-space: nowrap;
   overflow : hidden;
   margin-top : 15px;
   user-select: none;
   .tab {
      display : inline-block;
      height : 40px;
      line-height : 40px;
      border-bottom : 1px solid rgba( 255, 255, 255, 0 );
      color : white;
      margin-right : 20px;
      font-weight : 900;
      .TransAllCubic( @transDurationVeryFast );
      &:hover {
         cursor : pointer;
         border-bottom : 1px solid @clrBrand;
      }
      &.active {
         color : @clrBrand;
         border-bottom : 1px solid @clrBrand;
         pointer-events: none;
         // TODO : probably don't need this...
         &:hover {
            cursor : default;
         }
      }
   }
}
