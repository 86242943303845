@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-home {
   .Panel();
   @media @querySmall {
      .BGimage( '@{pathProd}img/cover_img.png' );
   }
   @media @queryMedium {
      .BGimage( '@{pathProd}img/cover_img.png' );
   }
   video {
      &.underlay {
         position : fixed;
         left : 0px;
         top : 0px;
         right : 0px;
         bottom : 0px;
         width : 100%;
         height : 100%;
         object-fit : cover;
         -webkit-transform:translate3d(0, 0, 0);
      }
   }
   .bg-overlay {
      position : fixed;
      left : 0px;
      top : 0px;
      right : 0px;
      bottom : 0px;
      width : 100%;
      height : 100%;
      background-color : @clrBlackAlpha5;
      background-image : url( '@{pathProd}img/grid_overlay.png' );
      -webkit-transform:translate3d(0, 0, 0);
   }
   .panel {
      position : absolute;
      width : 100%;
      max-width : 450px;
      height : 100%;
      max-height : 450px;
      text-align : center;
      left : 0px;
      right : 0px;
      top : 0px;
      bottom : 0px;
      margin : auto;
      @media @querySmall {
         height : calc( 100vh - @heightTopBar - 20px );
         max-height : unset;
      }
      header {
         position : absolute;
         top : 0px;
         left: 0px;
         right : 0px;
         margin : 0px auto;
      }
      .middle{
         position : absolute;
         top: 0px;
         bottom : 0px;
         margin : auto 0px;
         height : 200px;
         width : 100%;
         h1{
            font-size : 60px;
         }
         p {
            font-size : 20px;
         }
      }
      footer {
         position : absolute;
         bottom : 0px;
         left: 0px;
         right : 0px;
         margin : 0px auto;
      }
   }
   &.has-polycast {
      background : @clrBG0;
      video {
         display : none;
      }
      .bg-overlay {
         display : none;
      }
      .panel {
         display : none;
      }
   }
}
