@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.aspect-container {
   position : absolute;
   left : 0px;
   right : 0px;
   top : 0px;
   margin : 0 auto;
   width : 100vw;
   max-width: @maxVideoWidth;
   height : 0px;
   padding-bottom : 56.25%;
}

.panel-polycast {
   position : absolute;
   width : 100%;
   height : 0px;
   padding-bottom : 56.25%;
   background : @clrBG2;
   [ id^='motion-path-' ] {
      width : 100%;
      height : 100%;
      position : absolute;
      pointer-events: none;
      top : 0px;
      left : 0px;
      z-index : @zIndexAboveAll;
   }
   .thumb {
      position : absolute;
      pointer-events: none;
      z-index : @zIndexAboveAll;
      // NOTE : we make it huge for iOS to rasterize - then scale
      width : 500px;
      height : 500px;
      transform: translate( -50%, -50% );
      filter: @filterWhite;
      &.red {
         filter: @filterRed;
      }
      &.yellow {
         filter: @filterYellow;
      }
      &.green {
         filter: @filterGreen;
      }
      &.purple {
         filter: @filterPurple;
      }
   }
   .tip-heart {
      position : absolute;
      pointer-events: none;
      z-index : @zIndexAboveAll;
      opacity : 0.1;
      width : 500px;
      height : 500px;
      transform: translate(-50%, -50%);
      &:before {
         position: absolute;
         display: inline-block;
         content: '';
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         filter : @filterRed;
         .BGimage('@{pathProd}img/icon_heart.svg');
      }
   }
}

.portals {
   border : 1px solid magenta;
   .portal {
      &.polycast-hud{
         width: 100px;
         height: 100px;
         user-select : none;
         .btn-talking{
            position : relative;
            border-radius: 50%;
            background : @clrBlackAlpha2;
            border : 3px solid @clrBlackAlpha5;
            width : 100%;
            height : 100%;
            text-align : center;
            line-height : 100px;
            color : @clrFG1;
            font-size : 24px;
            font-weight: 700;
            user-select : none;
            &:before {
               content: '';
               position : absolute;
               left : 0;
               width : 100%;
               height : 100%;
               .BGimage('@{pathProd}img/icon_mic.svg');
               opacity : 0.5;
            }
            &:hover {
               &:not(.talking){
                  background : rgba( 0, 255, 0, 0.3 );
                  &:before{
                     opacity : 1;
                     filter: @filterFG1;
                  }
               }
            }
            &.talking {
               background : rgba( 255, 0, 0, 0.3 );
               border : 3px solid @clrRed;
               &:before{
                  opacity : 1;
                  filter: @filterFG1;
               }
            }
         }
      }
   }
}
