@import "vars.less";
@import "mixins.less";

@heightBarBig : 50px;
@heightBarSmall : 36px;

body, html {
    position : absolute;
    margin : 0px;
    left : 0px;
    right : 0px;
    top : 0px;
    bottom : 0px;
    width : 100vw;
    height : 100vh;
    box-sizing: border-box;
    color: @clrFG1;
    padding: 0px;
    min-width : 100%;
    min-height : 100%;
    overflow : hidden;
    background : @clrBG0;
    font-family: "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-transform:translate3d( 0, 0, 0 );
    transform: translate3d(0, 0, 0);
}

*, *:before, *:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
    box-sizing: inherit;
    scroll-behavior: smooth;
    font-family: "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif;
    outline : none !important;
}

input[type=text], input[type=email], input[type=password] {
    .Input();
}

:root {
    --clr-brand : @clrBrand;
}

// region keyframes

@keyframes neon-glow-pink {
    0% {
        filter: drop-shadow(0px 0px 1px #fff)
        drop-shadow(0px 0px 3px #fff)
        drop-shadow(0px 0px 10px #ff80b3)
        drop-shadow(0px 0px 30px #ff4d94)
        drop-shadow(0px 0px 50px #ff0066);
    }

    100% {
        filter: drop-shadow(0px 0px 3px #fff)
        drop-shadow(0px 0px 6px #fff)
        drop-shadow(0px 0px 15px #ff80b3)
        drop-shadow(0px 0px 40px #ff4d94)
        drop-shadow(0px 0px 60px #ff0066);
    }
}

@keyframes neon-glow-rainbow {
    // red
    0% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF  )
        drop-shadow( 0px 0px 5px #F88A93 )
        drop-shadow( 0px 0px 15px #F65C6A )
        drop-shadow( 0px 0px 25px #F3293D );
    }

    // orange
    16.67% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF )
        drop-shadow( 0px 0px 5px #FBCBA0 )
        drop-shadow( 0px 0px 15px #FAB57B )
        drop-shadow( 0px 0px 25px #F99F53 );
    }

    // yellow
    33.34% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF  )
        drop-shadow( 0px 0px 5px #FDFCA6 )
        drop-shadow( 0px 0px 15px #FCFA84 )
        drop-shadow( 0px 0px 25px #FCFA61 );
    }

    // green
    50% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF )
        drop-shadow( 0px 0px 5px #A5FBAD )
        drop-shadow( 0px 0px 15px #83FA8E )
        drop-shadow( 0px 0px 25px #5DF96B );
    }

    // blue
    66.68% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF  )
        drop-shadow( 0px 0px 5px #9DF0FB )
        drop-shadow( 0px 0px 15px #77EAFB )
        drop-shadow( 0px 0px 25px #4FE4F9 );
    }

    // indigo
    83.35% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF )
        drop-shadow( 0px 0px 5px #989CC8 )
        drop-shadow( 0px 0px 15px #7075B3 )
        drop-shadow( 0px 0px 25px #434A9B );
    }

    // violet
    100% {
        filter: drop-shadow( 0px 0px 1px #FFFFFF )
        drop-shadow( 0px 0px 3px #FFFFFF  )
        drop-shadow( 0px 0px 5px #DB9AF8 )
        drop-shadow( 0px 0px 15px #CD72F6 )
        drop-shadow( 0px 0px 25px #BE49F4 );
    }
}
//endregion
