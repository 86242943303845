@import "../../../css/vars.less";
@import "../../../css/mixins.less";


.panel-dj-profile {
   button {
      .BtnDefault();
   }
   .create-dj-panel {
      .SubPanel();
   }
}
