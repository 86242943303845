@import "../../../css/vars.less";
@import "../../../css/mixins.less";
@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-tip-the-star {
  .Dialog( @widthDialog );
  text-align : center;
  .header {

  }
  .body {
    color : @clrFG0;
    min-height : unset;
    padding-top : 41px;
    .input {
      display : inline-block;
      input {
        .Input(90px);
        margin-bottom : 0px;
      }
    }
    label {
      color : @clrFG0;
      transform : none;
      font-size : 20px;
      display : inline-block;
      padding : 0px 10px;
    }
  }
  .footer {

  }
}


