@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-sign-in {
    .Dialog( @widthDialog );
    .body {
        padding : 0px;
        min-height : unset;
        .tab-bar {
            border-bottom: 1px solid @clrWhiteAlpha4;
            padding: 0;
            margin: 0 0 15px;
            text-align: left;
            width: 100%;
            display: inline-block;
            .tab {
                color: @clrWhite;
                border: none;
                background: unset;
                font-size: 19px;
                padding: 0 0 15px 0;
                margin: 0 20px 0 0;
                border-radius: 0;
                text-align: left;

                &.active {
                    color: @clrBrand;
                    border-bottom: 2px solid @clrBrand;
                    pointer-events: none;
                }

                &:hover, &:focus, &:active {
                    color: @clrBrand;
                }
            }
        }
    }
    .footer {
        text-align : right;
    }
}
