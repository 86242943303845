@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@dimBtn : 50px;

.bar-top {
    background : @clrBG1;
    color : @clrFG1;
    font-size : 24px;
    font-weight : 900;
    width : 100vw;
    height : @heightTopBar;
    position : absolute;
    top : 0px;
    left : 0px;
    right : 0px;
    // overflow : hidden;
    z-index : @zIndexChromeUnder;
    padding : 15px 0px 15px (@heightTopBar + 10px);
    @media @querySmall {
        height: @heightTopBarSmall;
        padding : 10px 0px 10px (@heightTopBarSmall + 8px);
    }
    @media @queryMedium {
        height: @heightTopBarSmall;
        padding : 10px 0px 10px (@heightTopBarSmall + 8px);
    }
    .btn-ddc {
        position : absolute;
        display : inline-block;
        background : transparent;
        border-radius: 50%;
        top : 10px;
        left : 10px;
        height : @heightTopBar - 20px;
        width : @heightTopBar - 20px;
        &:before {
            .TransAllCubic( @transDurationVeryFast );
            position : absolute;
            top: 0px;
            left : 0px;
            display : inline-block;
            content : '';
            .BGimage( '@{pathProd}img/icon_vibe_with_me.png' );
            height : @heightTopBar - 20px;
            width : @heightTopBar - 20px;
        }
        @media @querySmall {
            top : 5px;
            left : 5px;
            height : @heightTopBarSmall - 10px;
            width : @heightTopBarSmall - 10px;
            &:before {
                background-size : 80%;
                height : @heightTopBarSmall - 10px;
                width : @heightTopBarSmall - 10px;
            }
            &:hover {
                &:before {
                    background-size : 80%;
                    height : @heightTopBarSmall - 10px;
                    width : @heightTopBarSmall - 10px;
                }
            }
        }
        @media @queryMedium {
            top : 5px;
            left : 5px;
            height : @heightTopBarSmall - 10px;
            width : @heightTopBarSmall - 10px;
            &:before {
                top: 5px;
                left : 5px;
                height : @heightTopBarSmall - 10px;
                width : @heightTopBarSmall - 10px;
            }
            &:hover {
                &:before {
                    top: 5px;
                    left : 5px;
                    height : @heightTopBarSmall - 10px;
                    width : @heightTopBarSmall - 10px;
                }
            }
        }
    }
    .app-title {
        text-overflow: ellipsis;
        margin-right : 160px;
        white-space: nowrap;
        overflow : hidden;
    }
    .currency {
        position : absolute;
        top : 3px;
        right : 80px;
        width : 75px;
        height : @dimBtn;
        text-align : right;
        .NonSelectable();
        @media @querySmall {
            top: -3px;
        }
        @media @queryMedium {
            top: -3px;
        }
        .btn-plus {
            display : inline-block;
            position : absolute;
            right : 126px;
            -webkit-appearance: none;
            appearance: none;
            width : @dimBtn;
            height :@dimBtn;
            background : transparent;
            border : none;
            outline : none;
            &:focus, &:active {
                outline : none;
            }
            &:before {
                display : inline-block;
                content : ' ';
                background : url( '@{pathProd}img/icon_plus_green.svg' );
                background-repeat: no-repeat;
                background-position : center center;
                background-size: contain;
                width : 100%;
                height : 100%;
            }
        }
        .balance {
            display : inline-block;
            position : absolute;
            right : @dimBtn;
            top: 2px;
            height : @dimBtn;
            width : 79px;
            line-height : @dimBtn;
            font-size : 22px;
            font-weight : 900;
            text-align : right;
            font-family: Consolas, monospace;
        }
        .btn-coin {
            display : inline-block;
            position : absolute;
            right : 5px;
            -webkit-appearance: none;
            appearance: none;
            width : @dimBtn;
            height : @dimBtn;
            background : transparent;
            border : none;
            outline : none;
            &:focus, &:active {
                outline : none;
            }
            &:before {
                display : inline-block;
                content : ' ';
                background : url( '@{pathProd}img/icon_coin.png' );
                background-repeat: no-repeat;
                background-position : center center;
                background-size : contain;
                width : 100%;
                height : 100%;
            }
        }
        .menu {
            border : 1px solid @clrYellow;
            border-top : none;
            border-radius: 0px 0px 10px 10px;
            position : absolute;
            top : calc( @heightTopBar - 4px );
            right : -4px;
            width : @widthMenu;
            z-index : @zIndexChrome;
            background : @clrBG1;
            font-size : 16px;
            font-weight : 400;
            padding : 10px;
            // min-height : 200px;
            .item-menu {
                .NonSelectable();
                display : block;
                position : relative;
                color : @clrFG1;
                margin : 0px auto;
                text-align : left;
                padding : 12px 12px 12px @dimBtn;
                border-radius : 4px;
                font-weight : 700;
                font-size : 18px;
                outline : none;
                &:disabled, &.disabled {
                    pointer-events: none;
                    // background: @clrDisabledBG;
                    // border : 1px solid @clrDisabled;
                    color: @clrDisabled;
                    &:before {
                        filter: @filterDisabled !important;
                    }
                }
                &:hover {
                    background : @clrBG3;
                    outline : none;
                }
                &:focus {
                    outline : none;
                }
                &:before {
                    display : inline-block;
                    position : absolute;
                    width : 43px;
                    height : 43px;
                    content : '';
                    left : 0px;
                    top : 0px;
                    filter : @filterFG1;
                }
                &.btn-love {
                    &:before {
                        filter: @filterRed;
                        .BGimage( '@{pathProd}img/icon_heart.svg' );
                    }
                }
                &.icon-mojo {
                    &:before {
                        filter : @filterYellow;
                        .BGimage( '@{pathProd}img/icon_mojo.svg' );
                    }
                }
            }
        }
    }
}
