@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.portals {
  position : absolute;
  margin : 0px;
  left : 0px;
  right : 0px;
  top : 0px;
  bottom : 0px;
  width : 100vw;
  height : 100vh;
  z-index : @zIndexChrome;
  pointer-events: none;
  .portal{
    pointer-events: auto;
    position : absolute;
    z-index : @zIndexChromeAbove;
    top : @heightTopBar * 0.5;
    left : 50vw;
    transform: translateX( -50% );
  }
}
