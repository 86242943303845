@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.menu-item {
   .NonSelectable();
   display : block;
   position : relative;
   color : @clrFG1;
   margin : 0px auto;
   text-align : left;
   padding : 12px 12px 12px 50px;
   border-radius : 4px;
   font-weight : 700;
   font-size : 18px;
   outline : none;
   width : @widthMenuItem;
   &:hover, &:focus, &:active {
      background : @clrBG3;
      // transform: scale3d( 1.03, 1.03, 1.03 );
      outline : none;
   }
   &:before {
      content : '';
      display : inline-block;
      position : absolute;
      width : 43px;
      height : 43px;
      left : 0px;
      top : 0px;
      filter : @filterFG1;
   }
   &.no-icon {
      padding : 12px;
      &:before {
         display : none;
      }
   }
   &.icon-dancer {
      &:before {
         .BGimage( '@{pathProd}img/icon_dancer.svg' );
      }
   }
   &.icon-account {
      &:before {
         .BGimage( '@{pathProd}img/icon_settings.svg' );
      }
   }
   &.icon-mojo {
      &:before {
         .BGimage( '@{pathProd}img/icon_mojo.svg' );
      }
   }
   &.icon-subs {
      &:before {
         .BGimage( '@{pathProd}img/icon_star.svg' );
      }
   }
   &.icon-signout {
      &:before {
         .BGimage( '@{pathProd}img/icon_logout.svg' );
      }
   }
   &.icon-bar-graph {
      &:before {
         .BGimage( '@{pathProd}img/icon_adjust.svg' );
      }
   }
   &.icon-upload {
      &:before {
         transform: rotate(90deg);
         .BGimage('@{pathProd}img/icon_arrow_close.svg');
      }
   }
   &.icon-trash {
      &:before {
         .BGimage('@{pathProd}img/icon_trash.svg');
      }
   }
   &.icon-arrow-left {
      &:before {
         .BGimage('@{pathProd}img/icon_arrow_left.svg');
      }
   }
   &.icon-arrow-right {
      &:before {
         .BGimage('@{pathProd}img/icon_arrow_right.svg');
      }
   }
   &.icon-calendar {
      .BGimage('@{pathProd}img/icon_calendar.svg');
   }
}
