@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-polycast {
   .panel-stream {
      position : absolute;
      display : inline-block;
      width : @cellDim;
      height : @cellDim;
      background : @clrBG0;
      border : @hairline;
      color : @clrFG1;
      font-size : 20pt;
      font-weight : 700;
      z-index : 2;
      &.focused {
         // TODO : consider merging focused and topmost
         .GlowLight();
         z-index : @zIndexChromeUnder !important;
      }
      &.local {
         .away-vid {
            position : absolute;
            left : 0px;
            top : 0px;
            height : 100%;
            width : 100%;
            z-index : 1;
            background : @clrBlack;
            object-fit: contain;
            pointer-events: none;
         }
      }
      &.star {
         video {
            position : absolute;
            left : 0px;
            top : 0px;
            height : 100%;
            width : 100%;
            z-index : 1;
            background : @clrBlack;
            object-fit: contain;
            pointer-events: none;
         }
      }
      &.dragging {
         .chat-panel-mini {
            display : none;
         }
      }
      &.topmost {
         z-index : @zIndexChromeUnder !important;
      }
      &:hover {
         cursor : pointer;
      }
      &.targeted {
         border : 3px solid @clrWhite;
      }
      &.broadcast {
         border : 3px solid @clrBlue;
         color : @clrFG1;
         &.targeted {
            border : 3px solid @clrWhite;
         }
      }
      .Coords();
      .CoordsChildren();
      .remote-video-container {
         position : absolute;
         left : 0px;
         top : 0px;
         height : 100%;
         width : 100%;
         overflow : hidden;
         text-align : center;
         video {
            position : absolute;
            left : 0px;
            top : 0px;
            height : 100%;
            width : 100%;
            z-index : 1;
            background : @clrBlack;
            object-fit: contain;
            pointer-events: none;
            &#away-vid {
               z-index : 2;
               object-fit : cover;
            }
         }
         header.ch-nameplate {
            position : absolute;
            z-index : 1;
            padding: 0px 10px;
            height : 39px;
            line-height : 39px;
            max-width: calc(100% - 2rem);
            backdrop-filter: blur( 20px );
            background-color: rgba( 0, 0, 0, 0.2 );
            border-radius: 6px;
            color: @clrFG1;
            bottom : 0px;
            left : 50%;
            -webkit-transform: translateX( -50% );
            transform: translateX( -50% );
            overflow : hidden;
            p.ch-text {
               font-size: 0.875rem;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
               margin: 0px;
            }
         }
         &.hidden {
            video {
               filter: blur(15px);
            }
         }
      }
      .overlay {
         position : absolute;
         left : 0px;
         top : 0px;
         height : 100%;
         width : 100%;
         z-index : 3;
         background : @clrBlackAlpha0;
         .TransAllCubic( @transDuration );
      }
   }
}

