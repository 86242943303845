@import "../../../css/vars.less";
@import "../../../css/mixins.less";
.panel-stream {
    &:hover {
        .stream-panel-hud {
            opacity: 1;
        }
    }
    .stream-panel-hud {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0px;
        left: 0px;
        opacity: 0;
        background: rgba(0, 0, 0, 0.5);
        .TransAllCubic(0.4s);
        z-index: 4;
        overflow: hidden;
    }
    button {
        .CoordsStreamPanel();
        .NonSelectable();
        .TransAllCubic(@transDurationVeryFast);
        transform: scale(0.95);
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        display: inline-block;
        position: absolute;
        cursor: pointer;
        border: none;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        width: @btnDimHUD;
        height: @btnDimHUD;
        &:before {
            filter : @filterFG1;
            content: '';
            display: inline-block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: @btnDimHUD;
            height: @btnDimHUD;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.4);
            outline: none;
            &:before {
                color: cornflowerblue;
            }
        }
        //region icons
        &.icon-img {
            &:before {
                .BGimage('@{pathProd}img/icon_img.svg');
            }
        }
        &.icon-vid-camera {
            &:before {
                .BGimage('@{pathProd}img/icon_vid_camera.svg');
            }
            &.off {
                &:before {
                    .BGimage('@{pathProd}img/icon_vid_camera_off.svg');
                    filter: @filterRed;
                }
            }
        }
        &.icon-mic {
            &:before {
                .BGimage('@{pathProd}img/icon_mic.svg');
            }
            &.off {
                &:before {
                    .BGimage('@{pathProd}img/icon_mic_off.svg');
                    filter: @filterRed;
                }
            }
        }
        &.icon-screen {
            &:before {
                .BGimage('@{pathProd}img/icon_screen.svg');
            }
        }
        &.icon-cogwheel {
            &:before {
                .BGimage('@{pathProd}img/icon_cogwheel.svg');
            }
        }
        &.icon-heart {
            &:before {
                .BGimage('@{pathProd}img/icon_heart.svg');
            }
        }
        &.icon-dm {
            &:before {
                .BGimage('@{pathProd}img/icon_dm.svg');
            }
        }
        &.icon-eye {
            &:before {
                .BGimage('@{pathProd}img/icon_eye.svg');
            }
            &.off {
                &:before {
                    filter: @filterRed;
                    .BGimage('@{pathProd}img/icon_eye_off.svg');
                }
            }
        }
        &.icon-speaker {
            &:before {
                .BGimage('@{pathProd}img/icon_speaker.svg');
            }
            &.off {
                &:before {
                    filter: @filterRed;
                    .BGimage('@{pathProd}img/icon_speaker_off.svg');
                }
            }
        }
        &.icon-gift {
            &:before {
                .BGimage('@{pathProd}img/icon_gift.svg');
            }
        }
        &.icon-fullscreen {
            &:before {
                .BGimage('@{pathProd}img/icon_fullscreen.svg');
            }
            &.off {
                &:before {
                    .BGimage('@{pathProd}img/icon_fullscreen_off.svg');
                }
            }
        }
        //endregion
    }
}
