@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.panel-fx {
  width : 100%;
  height : 100%;
  z-index : @zIndexChrome;
  position : absolute;
  top : 0px;
  left : 0px;
  padding : 0px;
  margin : 0px;
  outline : none;
  filter : none;
  .svg-in-object {
    display : inline-block;
    position : absolute;
    width : 100%;
    height : 100%;
    left : 0px;
    top : 0px;
    margin : 0px;
    padding : 0px;
    filter : inherit;
    z-index : 1;
    pointer-events: none;
    &.white {
      filter : @filterFG1;
    }
    &.red {
      filter : @filterRed;
    }
    &.yellow {
      filter : @filterYellow;
    }
    &.green {
      filter : @filterGreen;
    }
    &.purple {
      filter : @filterPurple;
    }
  }
}
