/* media queries */
/* paths {baseURL} */
/* dimensions and coords */
/* z-indices */
/* time */
/* overrides */
/* old colors
    @clrBlack : #000000;
    @clrOffBlack : #1F1F23;
    @clrOffBlackDark : #18181B;
    @clrWhite : #FFFFFF;
    @clrBGlight : #333642;
    @clrBG: #373535;
    @clrFG: #545554;
    @clrBGpanel : rgba( 255, 255, 255, 0.2 );
    @clrChatEnteredBG : #0F5B32;
    @clrChatExitedBG : #660d0d;
    @clrBrand : #57B74D;
    @clrBrandAlpha : rgba( 87, 183, 77, 0.85 );
    @clrBtnDown : #0f8d4c;
    @clrError : @clrRed;//#EF5455;
    // @clrYellow : #FFCB6B;
*/
/* filters */
/* images */
/* borders */
.PosterSmall .poster:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/vibe_poster_small.svg');
}
.PosterMedium .poster:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/vibe_poster_medium.svg');
}
.panel-stream:hover .stream-panel-hud {
  opacity: 1;
}
.panel-stream .stream-panel-hud {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s cubic-bezier(0.7, 0.01, 0.3, 1);
  -moz-transition: all 0.4s cubic-bezier(0.7, 0.01, 0.3, 1);
  -o-transition: all 0.4s cubic-bezier(0.7, 0.01, 0.3, 1);
  transition: all 0.4s cubic-bezier(0.7, 0.01, 0.3, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 4;
  overflow: hidden;
}
.panel-stream button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s cubic-bezier(0.7, 0.01, 0.3, 1);
  -moz-transition: all 0.2s cubic-bezier(0.7, 0.01, 0.3, 1);
  -o-transition: all 0.2s cubic-bezier(0.7, 0.01, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.7, 0.01, 0.3, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform: scale(0.95);
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
}
.panel-stream button[data-coord^="a"] {
  left: 0px;
}
.panel-stream button[data-coord^="b"] {
  left: 40px;
}
.panel-stream button[data-coord^="c"] {
  left: 80px;
}
.panel-stream button[data-coord^="d"] {
  right: 120px;
}
.panel-stream button[data-coord^="e"] {
  right: 0px;
}
.panel-stream button[data-coord*="1"] {
  top: 0px;
}
.panel-stream button[data-coord*="3"] {
  bottom: 0px;
}
.panel-stream button:before {
  filter: invert(96%) sepia(5%) saturate(169%) hue-rotate(186deg) brightness(97%) contrast(91%);
  content: '';
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
}
.panel-stream button:hover {
  background: rgba(255, 255, 255, 0.4);
  outline: none;
}
.panel-stream button:hover:before {
  color: cornflowerblue;
}
.panel-stream button.icon-img:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_img.svg');
}
.panel-stream button.icon-vid-camera:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_vid_camera.svg');
}
.panel-stream button.icon-vid-camera.off:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_vid_camera_off.svg');
  filter: invert(25%) sepia(47%) saturate(5509%) hue-rotate(342deg) brightness(89%) contrast(95%);
}
.panel-stream button.icon-mic:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_mic.svg');
}
.panel-stream button.icon-mic.off:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_mic_off.svg');
  filter: invert(25%) sepia(47%) saturate(5509%) hue-rotate(342deg) brightness(89%) contrast(95%);
}
.panel-stream button.icon-screen:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_screen.svg');
}
.panel-stream button.icon-cogwheel:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_cogwheel.svg');
}
.panel-stream button.icon-heart:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_heart.svg');
}
.panel-stream button.icon-dm:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_dm.svg');
}
.panel-stream button.icon-eye:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_eye.svg');
}
.panel-stream button.icon-eye.off:before {
  filter: invert(25%) sepia(47%) saturate(5509%) hue-rotate(342deg) brightness(89%) contrast(95%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_eye_off.svg');
}
.panel-stream button.icon-speaker:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_speaker.svg');
}
.panel-stream button.icon-speaker.off:before {
  filter: invert(25%) sepia(47%) saturate(5509%) hue-rotate(342deg) brightness(89%) contrast(95%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_speaker_off.svg');
}
.panel-stream button.icon-gift:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_gift.svg');
}
.panel-stream button.icon-fullscreen:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_fullscreen.svg');
}
.panel-stream button.icon-fullscreen.off:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://vibewith.me/img/icon_fullscreen_off.svg');
}
