@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.dialog-create-event {
  .Dialog( @widthDialog );
  padding: 50px 40px 70px 40px;
  .body {
    padding-top: 25px;
    .input-tags {
      // margin-bottom : 17px;
      input {
        width : 100%;
      }
      .tag-labels{
        width : 100%;
      }
    }
  }
}


