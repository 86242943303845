@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.payment-box {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   border-radius: 8px;
   padding: 30px;
   background: @clrBG0;
   width: @widthDialog * 1.2;
   height: 620px;
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   color: @clrFG1;
   label {
      color: @clrFG1;
      font-weight: 300;
      letter-spacing: 0.025em;
   }
   button {
      white-space: nowrap;
      border: 0;
      outline: 0;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      // TODO : consider removing shadow?
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      color: @clrFG1;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.025em;
      background : @clrBG2;
      // background-color: #6772e5;
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin-top: 10px;
      &:hover {
         cursor: pointer;
         background : @clrBG3;
         // background-color: #7795f8;
         transform: translateY(-1px);
         box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      }
   }

   input,
   .StripeElement {
      display: block;
      margin: 10px 0 20px 0;
      max-width: 500px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: "Source Code Pro", monospace;
      // burk?
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      background: white;
   }

   input::placeholder {
      color: #aab7c4;
   }

   input:focus,
   .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
   }

   .StripeElement.IdealBankElement,
   .StripeElement.FpxBankElement,
   .StripeElement.PaymentRequestButton {
      padding: 0;
   }

   .StripeElement.PaymentRequestButton {
      height: 40px;
   }
}


