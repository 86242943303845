@import "../../../css/vars.less";
@import "../../../css/mixins.less";

@arrowDims: 60px;
@dimTile : 400px;

.dialog-purchase-mojo {
  .Dialog();
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: 700px;
  transform : unset;
  border-radius: 8px;
  background: #1f1f23;
  .DropShadow();
  text-align: center;
  width: 540px;
  padding: 80px 30px 100px;
  // overflow: hidden;
  .btn-buy {
    .BtnDefault();
    position : absolute;
    bottom : 15px;
    right : 15px;
  }
  .btn-close {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    width: 44px;
    height: 44px;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    color: white;

    &:after {
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       content: '\D7';
       font-size: 30px;
       font-weight: 600;
       line-height: 44px;
       text-align: center;
       margin-top: -4.4px;
    }

    &:hover, &:focus {
      color: red;
      outline: none;
      cursor: pointer;
      background: transparent;
      border: none;
   }
  }

  .arrow {
    display: inline-block;
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: calc( 50% - ( @arrowDims * 0.5 ));
    width: @arrowDims;
    height: calc( @arrowDims + 10px );
    font-size: 40px;
    line-height: @arrowDims;
    z-index: 10;
    text-align: center;
    font-weight: 900;
    // border-radius: 50%;
    // background : rgba( 255, 255, 255, 0.6 );
  }

  .icon-arrow-left {
    left: 1px;
    &:before {
      .BGimage('@{pathProd}img/icon_arrow_left.svg');
      filter: @filterFG1;
    }
  }
  .icon-arrow-right {
    right: 1px;
    &:before {
      .BGimage('@{pathProd}img/icon_arrow_right.svg');
      filter: @filterFG1;
    }
  }

  .slide {
    box-sizing: border-box;
    user-select : none;
    position : absolute;
    height : 527px;
    width : @dimTile;
    border : 3px solid @clrYellow;
    top : 69px;
    left: 69px;
    font-size : 52px;
    line-height : 500px;
    text-align : center;
    border-radius : 10px;
    display : inline-block;
    transform : scale3d( 1, 1, 1 );
    color : white;
    margin : 0;
    overflow : hidden;
    opacity: 0;
    transition-duration: 1s ease;
    &.active {
      opacity: 1;
      transition-duration: 1s;
      transform: scale(1.08);
    }
  }
}