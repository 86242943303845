@import "../../../css/vars.less";
@import "../../../css/mixins.less";

.input-checkbox {
  .MuiFormControlLabel-root{
    display : block !important;
    text-align : left;
    .MuiTypography-root {
      font-size : @unset;
      letter-spacing : @unset;
      font-family : @unset;
      text-transform : lowercase;
    }
  }
}
